import { DottedTag, TagColors } from 'app/components/common/DottedTag';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { AgtClusterVM } from 'app/stores/ui/agt.cluster.ui.store';
import { CLUSTERMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

// interface IAgtBranchenPlanungWidget {
// 	agt: AgenturModel;
// 	year: number;
// }

export const AgtClusterWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore, agtPlanUiStore } = useStore();
	const agt = agenturUiStore.current;

	return (
		<>
			{agt && (
				<Widget title="Agentur Cluster" modifier="is-agenturcluster-widget" widgetId={props.widgetKey}>
					<AgtClusterTagList year={agtPlanUiStore.currentYear} />
				</Widget>
			)}
		</>
	);
});

interface IAgtClusterTagList {
	year: number;
}

export const AgtClusterTagList = observer((props: IAgtClusterTagList) => {
	const { agenturUiStore, agtClusterUiStore } = useStore();
	const agt = agenturUiStore.current;
	const [cluster, setCluster] = useState<AgtClusterVM>();

	useEffect(() => {
		if (agt) {
			agtClusterUiStore.filterByAgtIdAndYear(agt?.agtId, props.year).then((c) => {
				setCluster(c[0]);
			});
		}
	}, [agtClusterUiStore, agt, props.year]);

	if (!cluster) return <span>Keine Clusterdaten</span>;

	return (
		<>
			<div className="dotted-tag-list">
				{cluster.betreuungVbl && <AgtClusterDot value={cluster.betreuungVbl} text={`VBL: ${cluster.betreuungVbl}`} />}
				{cluster.betreuungVas && <AgtClusterDot value={cluster.betreuungVas} text={`VAS: ${cluster.betreuungVas}`} />}
				{cluster.betreuungBbs && <AgtClusterDot value={cluster.betreuungBbs} text={`BBS: ${cluster.betreuungBbs}`} />}
				{cluster.betreuungBsg && <AgtClusterDot value={cluster.betreuungBsg} text={`BSG: ${cluster.betreuungBsg}`} />}
				{cluster.betreuungKvs && <AgtClusterDot value={cluster.betreuungKvs} text={`KVS: ${cluster.betreuungKvs}`} />}
				{cluster.betreuungKme && <AgtClusterDot value={cluster.betreuungKme} text={`KME: ${cluster.betreuungKme}`} />}
				{cluster.betreuungUb && <AgtClusterDot value={cluster.betreuungUb} text={`UB: ${cluster.betreuungUb}`} />}
			</div>
		</>
	);
});

interface IAgtClusterDot {
	text?: string;
	value?: number;
}
const AgtClusterDot = (props: IAgtClusterDot) => {
	const { text, value } = props;

	let col = TagColors.turquoise;
	let val = value || 1;

	if (val >= 2) {
		col = TagColors.purple;
	}
	if (val >= 3) {
		col = TagColors.yellow;
	}

	return (
		<>
			<DottedTag color={col} text={`${text || ''}`} />
		</>
	);
};
