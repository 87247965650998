import { AgenturModel } from 'app/models/agentur.model';
import { AgtErrungenschaftenDefinition } from 'app/models/errungenshaften/agt.errungenschaften.def';
import { ErrungenschaftModel, ErrungenschaftState, ErrungenschaftSummaryModel, IErrungenschaft } from 'app/models/errungenshaften/errungenschaften.model';
import _ from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtGpStore } from '../agt.gp.store';
import { AgtZielStore } from '../agt.ziel.store';
import { KvAgenturYearErrungenschaftenModel, KvAgenturYearErrungenschaftenStore } from '../kv.agentur.year.errungenschaften.store';
import { AgtBestandUiStore } from './agt.bestand.ui.store';
import { AgtBranchenplanungUiStore } from './agt.branchenplanung.ui.store';
import { AgtDigigFaktorUiStore } from './agt.digifaktor.ui.store';
import { AgtLeadNowCheckUiStore } from './agt.leadnowcheck.ui.store';
import { AgenturListUiStore } from './agt.list.ui.store';
import { AgtProdUiStore } from './agt.prod.ui.store';
import { AgtRingUiStore } from './agt.ring.ui.store';
import { GPMAXYEAR } from './berichte.ui.store';
import { AgtAkumaPrimeUiStore } from './agt.akumaprime.ui.store';
import { AgtDigitalreportUiStore } from './agt.digitalreport.ui.store';
import { AgtAkumaPlanerUiStore } from './agt.akumaplaner.ui.store';
import { BaseUiStore } from './base.ui.store';
import Config from 'Config';

export class AgtErrungenschaftenVM {
	constructor(agt: AgenturModel) {
		this.agt = agt;
		this.items = AgtErrungenschaftenDefinition.map((e) => new ErrungenschaftModel(e));
	}
	agt: AgenturModel;

	items: ErrungenschaftModel[] = [];

	getItemByKey(key: string) {
		return this.items.find((x) => x.key === key);
	}

	@computed
	get sortedByAchieved() {
		return _.sortBy(this.items, (i) => i.stateAsNumber).reverse();
	}
}

class AgtChanged {
	constructor(opts: any) {
		this.agt = opts.agt;
		this.changes = opts.changes;
	}
	agt: AgenturModel;
	changes: ChangedErrungeschaft[];
}

class ChangedErrungeschaft {
	constructor(opts: any) {
		this.def = opts.def;
		this.kv = opts.kv;
	}
	def: IErrungenschaft;
	kv: KvAgenturYearErrungenschaftenModel;
}

export class AgtErrungenschaftenUiStore extends BaseUiStore<AgtErrungenschaftenVM> {
	name = 'AgtErrungenschaftenUiStore';
	agenturStore: AgenturStore;
	agtLeadNowCheckUiStore: AgtLeadNowCheckUiStore;
	agenturListUiStore: AgenturListUiStore;
	agtBestandUiStore: AgtBestandUiStore;
	agtDigigFaktorStore: AgtDigigFaktorUiStore;
	agtRingUiStore: AgtRingUiStore;
	agtGpStore: AgtGpStore;
	agtZielStore: AgtZielStore;
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	agtProdUiStore: AgtProdUiStore;
	kvAgenturYearErrungenschaftenStore: KvAgenturYearErrungenschaftenStore;
	agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
	agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore;
	agtDigitalreportUiStore: AgtDigitalreportUiStore;

	constructor(
		agenturStore: AgenturStore,
		agenturListUiStore: AgenturListUiStore,
		agtBestandUiStore: AgtBestandUiStore,
		agtDigigFaktorStore: AgtDigigFaktorUiStore,
		agtRingUiStore: AgtRingUiStore,
		agtGpStore: AgtGpStore,
		agtZielStore: AgtZielStore,
		agtLeadNowCheckUiStore: AgtLeadNowCheckUiStore,
		agtBranchenplanungUiStore: AgtBranchenplanungUiStore,
		agtProdUiStore: AgtProdUiStore,
		kvAgenturYearErrungenschaftenStore: KvAgenturYearErrungenschaftenStore,
		agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore,
		agtDigitalreportUiStore: AgtDigitalreportUiStore,
		agtAkumaPlanerUiStore: AgtAkumaPlanerUiStore
	) {
		super();
		makeObservable(this);
		this.agenturStore = agenturStore;
		this.agtLeadNowCheckUiStore = agtLeadNowCheckUiStore;
		this.agenturListUiStore = agenturListUiStore;
		this.agtBestandUiStore = agtBestandUiStore;
		this.agtDigigFaktorStore = agtDigigFaktorStore;
		this.agtRingUiStore = agtRingUiStore;
		this.agtGpStore = agtGpStore;
		this.agtZielStore = agtZielStore;
		this.agtBranchenplanungUiStore = agtBranchenplanungUiStore;
		this.agtProdUiStore = agtProdUiStore;
		this.kvAgenturYearErrungenschaftenStore = kvAgenturYearErrungenschaftenStore;
		this.agtAkumaPrimeUiStore = agtAkumaPrimeUiStore;
		this.agtDigitalreportUiStore = agtDigitalreportUiStore;
		this.agtAkumaPlanerUiStore = agtAkumaPlanerUiStore;
	}

	@observable
	currentKw: string = moment().format('YYYYww');
	@action
	setCurrentKw(kw: string) {
		this.currentKw = kw;
		this.items.forEach((vm) => {
			vm.items.forEach((er) => {
				er.currentKw = kw;
			});
		});
	}

	get kwOpts() {
		const result = [];
		const currentKw = parseInt(moment().format('ww'));
		const currentYear = moment().year();

		for (let i = currentKw; i >= 1; i--) {
			let month = ('0' + i).slice(-2);
			if (i > 9) {
				month = '' + i;
			}
			const x = `${currentYear}${month}`;
			result.push({
				label: x,
				value: x,
			});
		}
		return result;
	}

	// @observable
	// items: AgtErrungenschaftenVM[] = [];

	@observable
	isLoaded: boolean = false;

	@observable
	kvItems: KvAgenturYearErrungenschaftenModel[] = [];

	@computed
	get agtErrungenschaftenDefinition(): IErrungenschaft[] {
		const items = AgtErrungenschaftenDefinition.filter((e) => !e.visibleForRoles || e.visibleForRoles.includes(this.agtBestandUiStore.session.currentSteart!.id));
		return _.sortBy(items, 'key');
	}

	@computed
	get agtErrungenschaftenSummaryItems() {
		const defs = this.agtErrungenschaftenDefinition;
		const res: ErrungenschaftSummaryModel[] = [];

		defs.forEach((def) => {
			const sum = new ErrungenschaftSummaryModel(def);
			res.push(sum);
			this.items.forEach((vm) => {
				const eru = vm.items.find((i) => i.key === def.key);

				if (eru) {
					if (eru.stateAsNumber > 0) {
						sum.count++;
					}
					if (eru.isNewInCurrentKw) {
						sum.countCurrentKw++;
					}
				}
			});
		});

		return res;
	}

	async save() {
		const year = moment().year();
		const allKvs = await this.kvAgenturYearErrungenschaftenStore.findAll();
		this.kvItems = allKvs;
		const kvsYear = allKvs.filter((x) => x.year === year);
		let promises: any = [];
		let itemsToSave: KvAgenturYearErrungenschaftenModel[] = [];
		let created = 0;
		let changed = 0;
		let notChanged = 0;
		const steart = this.agenturStore.session.currentSteart!.id;
		for (const vm of this.items) {
			const agtKvs = kvsYear.filter((x) => x.agtId === vm.agt.agtId);
			for (const e of vm.items) {
				if (!e.canSee(steart)) {
					continue;
				}

				let kv = agtKvs.find((k) => k.key === e.key);
				if (!kv) {
					created++;
					kv = new KvAgenturYearErrungenschaftenModel({
						// bnrId,
						agtId: vm.agt.agtId,
						aid: vm.agt.agtId,
						bm: year,
						group: 'errungenschaften',
						key: e.key,
					});
				} else {
					if (kv.valueString === e.state) {
						notChanged++;
						// no change
						continue;
					}
					changed++;
					kv.addHistoryEntry();
				}
				kv.valueString = e.state;
				// the following props are not returned from the server. saves some bandwidth.. so not really necessary..
				kv.group = 'errungenschaften';
				// kv.bnrId = bnrId;
				itemsToSave.push(kv);
			}
			if (itemsToSave.length >= 29) {
				// await this.kvAgenturYearErrungenschaftenStore.saveMany(itemsToSave);
				promises.push(this.kvAgenturYearErrungenschaftenStore.saveMany(itemsToSave));
				itemsToSave = [];
			}
			if (promises.length > 5) {
				await Promise.all(promises);
				promises = [];
			}
		}
		await this.kvAgenturYearErrungenschaftenStore.saveMany(itemsToSave);
		await Promise.all(promises);
		console.log('created: ' + created + ' changed: ' + changed + ' notChanged: ' + notChanged);
		// reload after possible changes
		this.kvItems = await this.kvAgenturYearErrungenschaftenStore.findAll();
	}

	customClearCache() {
		this.kvAgenturYearErrungenschaftenStore.clearCache();
	}

	@computed
	get currentUserIsVbl() {
		return this.agenturStore.session.isVbl;
	}

	doCalcs: boolean = false;
	async _baseLoad() {

		let allKvs = await this.kvAgenturYearErrungenschaftenStore.findAll();
		if (this.items.length === 0) {
			let agts = await this.agenturStore.findAll();
			agts = agts.filter((a) => a.supportsDigiFaktor);
			runInAction(() => {
				let allKvsL = allKvs.slice();
				this.items = agts.map((agt) => {
					const vmAgt = new AgtErrungenschaftenVM(agt);
					let agtKvs: KvAgenturYearErrungenschaftenModel[];
					[allKvsL, agtKvs] = this.filterAndSplice(allKvsL, (k) => k.agtId === agt.agtId);
					vmAgt.items.forEach((e) => {
						const kv = agtKvs.find((k) => k.key === e.key);
						if (kv) {
							e.setStateFromString(kv.valueString);
							e.history = kv.history;
							e.modified = kv.modified;
						}
					});
					return vmAgt;
				});
			});
		}


		// if (doCalcs && this.currentUserIsVbl) {
		if (this.doCalcs && !Config.disableErrungenschaften) {
			const promises = [
				this.loadJpg(),
				this.loadBestandszuwachs(),
				this.loadDigiFaktor(),
				this.loadRing(),
				this.loadIdd(),
				this.loadGesamtbewertungsZiel(),
				this.loadBreitenmass(),
				this.loadAkumaPrime(),
				this.loadDigitalReport(),
				this.loadAkumaPlaner()
			];
			await Promise.all(promises);
		}
		this.doKwk();

		console.time('errungnenschaften history map');
		runInAction(() => {
			this.items.forEach((vmAgt) => {
				// const agtKvs = allKvs.filter((k) => k.agtId === vmAgt.agt.agtId);
				let agtKvs: KvAgenturYearErrungenschaftenModel[];
				[allKvs, agtKvs] = this.filterAndSplice(allKvs, (k) => k.agtId === vmAgt.agt.agtId);
				vmAgt.items.forEach((e) => {
					const kv = agtKvs.find((k) => k.key === e.key);
					if (kv) {
						e.history = kv.history;
					}
				});
			});
		});
		console.timeEnd('errungnenschaften history map');
		allKvs.forEach((kv) => {
			this.items.find((v) => v.items[0]);
		});

		runInAction(() => {
			this.kvItems = allKvs;
			if (this.loadingPromiseResolver) {
				this.loadingPromiseResolver();
			}
			this.isLoaded = true;
		});
	}

	async findByAgtId(agtId: number) {
		await this.load();
		return this.items.find((a) => a.agt.agtId === agtId);
	}

	async doKwk() {
		await this.agenturListUiStore.load();
		const items = this.agenturListUiStore.itemsForErrungenschaften;
		items.forEach((i) => {
			reaction(
				() => i.kwk,
				() => {
					const agt = this.items.find((a) => a.agt.agtId === i.agtId)!;
					const eKwk = agt.items.find((e) => e.key === 'agt.kwk')!;
					eKwk.setStateBool(i.kwk === 1);
				},
				{ fireImmediately: true },
			);
		});
		// console.log('done kwk');
	}

	async loadJpg() {
		if (!this.agenturStore.session.currentUser!.isVbl) {
			return;
		}
		await this.agenturListUiStore.load();
		const items = this.agenturListUiStore.itemsForErrungenschaften;
		items.forEach((i) => {
			reaction(
				() => i.jpgDone || i.apDone,
				() => {
					const agt = this.items.find((a) => a.agt.agtId === i.agtId)!;
					const eJpg = agt.items.find((e) => e.key === 'agt.jpg')!;
					eJpg.setStateBool(i.jpgDone);
					const eAP = agt.items.find((e) => e.key === 'agt.ap')!;
					eAP.setStateBool(i.apDone);
				},
				{ fireImmediately: true },
			);
		});
		// console.log('done jpg');
	}

	async loadBestandszuwachs() {
		if (this.agenturStore.session.currentUser!.isMulti) {
			return;
		}
		await this.agtBestandUiStore.load();
		const items = this.agtBestandUiStore.items;
		items.forEach((i) => {
			const agt = this.items.find((a) => a.agt.agtId === i.agtId)!;
			if (!agt) {
				return;
			}
			const e = agt.items.find((e) => e.key === 'agt.bestandswachstum')!;
			e.setStateBool(false);
			if (i.bestand && i.bestand.deltaBt && i.bestand.deltaBt >= 3) {
				e.setStateBool(true);
			}
		});
		// console.log('done bestand');
	}

	async loadDigiFaktor() {
		console.log('DIGI FAKTOR BIS AUF WEITERES DISABLED');
		return;

		await this.agtDigigFaktorStore.load();
		const items = this.agtDigigFaktorStore.items;
		items.forEach((i) => {
			const agt = this.items.find((a) => a.agt.agtId === i.agtId)!;
			if (!agt) {
				return;
			}
			const e = agt.items.find((e) => e.key === 'agt.digifaktor')!;
			e.setStateBool(false);
			if (i.DF && i.DF >= 100) {
				e.setStateBool(true);
			}
		});
		console.log('done digi');
	}

	async loadRing() {
		if (this.agenturStore.session.currentUser!.isMulti) {
			return;
		}
		const items = await this.agtRingUiStore.findRingOverview(GPMAXYEAR);
		items.forEach((i) => {
			const agt = this.items.find((a) => a.agt.agtId === i.agt.agtId)!;
			if (!agt) {
				return;
			}
			const e = agt.items.find((e) => e.key === 'agt.ring')!;
			let ergebnis = -1;
			if (i.achievedMedal === 'bronze') {
				ergebnis = 1;
			} else if (i.achievedMedal === 'silber') {
				ergebnis = 2;
			} else if (i.achievedMedal === 'gold') {
				ergebnis = 3;
			}
			e.gsbLevels = [3, 2, 1];
			e.setStateGSB(ergebnis);
		});
		// console.log('done ring');
	}

	async loadIdd() {
		const items = await this.agtZielStore.findByPlanKey('idd', GPMAXYEAR);
		items.forEach((i) => {
			const agt = this.items.find((a) => a.agt.agtId === i.agtId)!;
			if (!agt) {
				// console.warn('errungenschaften idd. Could not find agt ' + i.agtId);
				return;
			}
			const e = agt.items.find((e) => e.key === 'agt.idd')!;
			e.setState(ErrungenschaftState.off);
			if (i.ergebnis > 900) {
				e.setState(ErrungenschaftState.gold);
			}
		});
		// console.log('done idd');
	}

	async loadGesamtbewertungsZiel() {
		// 1. Fall. Es ist eine Ges.Bew in der BP vorhanden
		// vergleichen mit:
		// Bei nicht Pro3 Agenturen:        mit dem Istwert der EH Gesamtbewertung
		// Bei Pro3 Agenturen:                    mit dem Istwert des Fahrstuhls (Regel gerne wie gehabt…es kommt hier nur auf den Istwert an)

		// 2. Fall keine BP vorhanden
		// Wenn das EH-Widget der Agentur über Ziel ist, dann gilt die Errungenschaft
		// Nicht ganz, da wir hier auf die „niedrigste“ Stufe bei den AP-Stufen der Pro3 Agenturen abzielen.
		// Bei Nicht-Pro3 passt die Widget-Logik

		await this.agtBranchenplanungUiStore.load();

		const bps = await this.agtBranchenplanungUiStore.findByYear(moment().year());
		for (const agt of this.items) {
			const p = await this.agtProdUiStore.findByPlanKeyAndAgtId('gesamtbewertung', agt.agt.agtId, GPMAXYEAR);
			const e = agt.items.find((e) => e.key === 'agt.gesamtbewertung')!;
			if (!p || !p.posVal) {
				continue;
			}
			let ziel = p.posVal.ziel;
			const bp = bps.find((bp) => bp.agtId === agt.agt.agtId);
			if (bp && bp.gesamtBewertung && bp.gesamtBewertung > 0) {
				ziel = bp.gesamtBewertung;
			}
			e.setState(ErrungenschaftState.off);
			if (p.posVal.ergebnis > ziel) {
				e.setState(ErrungenschaftState.gold);
			}
			// if (agt.agt.agtId === 702100310) {
			// 	console.warn('hackkkkk ');
			// 	console.warn('hackkkkk ');
			// 	console.warn('hackkkkk ');
			// 	e.setState(ErrungenschaftState.gold);
			// }
		}
		// console.log('done gesamtbewrtung');
	}

	async loadBreitenmass() {
		/*
	
			Breitenmaß aus VBL Sicht           // erfüllt oder nicht erfüllt + <60 und 160>
			Hier nehme ich den Wert aus dem Produktiväten Zwischenstand?
			Ja…dies kann beim VBL sowas sein wie 112%  Ziel Agentur 100.000 und hat Ist-Wert von 112.000; dann gilt es als erfüllt
			Und der die Errungenschaft gibt nur wenn der Wert zwischen 60 und 160 liegt? 
				Info hat glaub ich dich verwirrt, erstmal ignorieren
 
	
	
		*/

		for (const agt of this.items) {
			const p = await this.agtProdUiStore.findByPlanKeyAndAgtId('produktivitaet', agt.agt.agtId, GPMAXYEAR);
			const e = agt.items.find((e) => e.key === 'agt.produktivitaet')!;
			e.setState(ErrungenschaftState.off);
			if (p && p.posVal) {
				e.setStateBool(p.posVal.ergebnis > p.posVal.ziel);
			}
		}
		// console.log('done produktivitaet');
	}

	async loadAkumaPrime() {
		/*
	
			wenn mindestens 6 Wochen Akuma Prime teilnahme erfolgt sind. 
		*/

		await this.agtAkumaPrimeUiStore.load();

		for (const agt of this.items) {
			const agtCheck = this.agtAkumaPrimeUiStore.checkAgtParticipation(agt.agt.agtId);

			const e = agt.items.find((e) => e.key === 'agt.akumaprime')!;
			if (agtCheck) {
				e.setState(ErrungenschaftState.gold);
			} else {
				e.setState(ErrungenschaftState.off);
			}
			// if (agt.agt.agtId === 704200580) {
			// 	console.log('704200580', e.state)

			// }
		}
		// console.log('done akuma prime');
	}

	async loadAkumaPlaner() {
		/*
	
			wenn mindestens 6 Wochen 50% der Kontakte verarbeitet wurden
		*/

		await this.agtAkumaPlanerUiStore.load();

		for (const agt of this.items) {
			const count = this.agtAkumaPlanerUiStore.countPassedItemsForCurrentYear(agt.agt.agtId);
			const e = agt.items.find((e) => e.key === 'agt.akumaplaner')!;
			if (count >= 24) {
				e.setState(ErrungenschaftState.gold);
			} else if (count >= 12) {
				e.setState(ErrungenschaftState.silver);

			} else if (count >= 6) {
				e.setState(ErrungenschaftState.bronze);
			} else {
				e.setState(ErrungenschaftState.off);

			}
		}
		// console.log('done akuma planer');
	}

	async loadDigitalReport() {
		await this.agtDigitalreportUiStore.load();
		const vms = this.agtDigitalreportUiStore.items;

		for (const agt of this.items) {
			const digi = vms.find((vm) => vm.agt.agtId === agt.agt.agtId);

			const eMaz = agt.items.find((e) => e.key === 'agt.maz')!;
			const eWewe = agt.items.find((e) => e.key === 'agt.wewe')!;
			const eDigi = agt.items.find((e) => e.key === 'agt.digiid')!;

			eMaz.setState(ErrungenschaftState.off);
			eWewe.setState(ErrungenschaftState.off);
			eDigi.setState(ErrungenschaftState.off);

			if (digi) {
				if (digi.pos.prozMaz18 >= 60) {
					eMaz.setState(ErrungenschaftState.gold);
				}
				if (digi.pos.prozWewe18 >= 70) {
					eWewe.setState(ErrungenschaftState.gold);
				}
				if (digi.pos.prozDigiIdNeu >= 95) {
					eDigi.setState(ErrungenschaftState.gold);
				}
			}
		}
	}
}
