import { observer } from 'mobx-react';
import { Icon } from '../common/Icon';

interface IHandlungsempfehlungen {
	handlungsEmpfehlungen: string;
}

export const JpgHandlungsempfehlungen = observer((props: IHandlungsempfehlungen) => {
	const items = props.handlungsEmpfehlungen.split(',');

	return (
		<>
			<div className="content">
				<p>
					<strong className="title">Handlungsempfehlungen</strong>
				</p>
				<ul>
					{items.map((i) => (
						<li key={i}>{i}</li>
					))}
				</ul>
			</div>
		</>
	);
});

export const JpgAmisNowVorbereitungContent = () => {
	return (
		<>
			<div className="content">
				<p>
					<strong className="title">AMIS.NOW Vorbereitung</strong>
				</p>

				<ul>
					<li>
						<a target="_blank" href="https://amisonline.allianz.de/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug.html">
							AMIS.NOW – Der Umzug | amisonline.allianz.de
						</a>
					</li>
					<li>
						<a
							target="_blank"
							href="https://amisonline.allianz.de/content/dam/onemarketing/dechap/common/amis/documents/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug/20241030_umzugscheckliste.pdf"
						>
							20241030_umzugscheckliste.pdf (allianz.de)
						</a>
					</li>
					<li>
						<a target="_blank" href="https://amisonline.allianz.de/agentur-und-vertrieb/beratung-und-verkauf/amis-now/der-umzug/arbeiten-mit-amis-now.html">
							Übergangslösungen
						</a>
					</li>
				</ul>
				<p>Räumen Sie auf und trennen Sie sich von alten Dateien. Es macht Ihnen den Start mit AMIS.NOW leichter.</p>
				<p>Wie und bis Wann wollen Sie die Vorbereitung gestartet haben und abgeschlossen haben?</p>
				<p>
					Wie wollen Sie ihr Agenturteam einbinden? Ihre <strong>Wunsch-Kalenderwoche</strong> zur Migration festlegen (Februar bis Ende März)!
				</p>
				<p>
					<a href={'AMIS_NOW_JPG.pdf'} target="_blank" rel="noreferrer" className="button is-primary is-small has-icon is-inverted">
						<span>AMIS.NOW Bausteine</span> <Icon iconClass="external-link-square" />
					</a>
				</p>
			</div>
		</>
	);
};

export const JpgAmisNowQualiContent = () => {
	return (
		<>
			<div className="content">
				<p>
					<strong className="title">AMIS.NOW Qualifizierung</strong>
				</p>
				<ul>
					<li>
						<a target="_blank" href="https://akademien.allianz.de/app.html#!/training/943941358">
							AZ DE - AMIS.NOW einfach flexibel - Allianz Akademien
						</a>
					</li>
					<li>
						<a target="_blank" href="https://amisonline.allianz.de/ansprechpartner/digitale-spezialisten-einheit-dse/webcast-kalender.html">
							Webcast-Kalender | amisonline.allianz.de
						</a>
					</li>
				</ul>

				<p>Wann planen Sie Zeit ein für die Digitale Lernwelt AMIS.NOW ein? Für Sie und Ihre Mitarbeiter?</p>

				<p>
					<a href={'AMIS_NOW_JPG.pdf'} target="_blank" rel="noreferrer" className="button is-primary is-small has-icon is-inverted">
						<span>AMIS.NOW Bausteine</span> <Icon iconClass="external-link-square" />
					</a>
				</p>
			</div>
		</>
	);
};
