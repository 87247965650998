import React, { useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { Button } from 'app/components/common/Button';
import { notify } from 'app/components/common/notify';
import { runInAction } from 'mobx';

const TestClearContainer = observer((props: RouteComponentProps) => {
	const { agtPlanStore, userSettingsStore, agtErrungenschaftenUiStore, pdfStore, uiStore } = useStore();
	useEffect(() => {


	}, [agtPlanStore]);
	const clearDocsForUser = () => {
		agtPlanStore.clearPlanungForTest().then(() => {
			notify('Done', '', 'info');
			window.location.reload();
		})
	}
	const resetWochenMailForUser = () => {

		const req = { kw: agtErrungenschaftenUiStore.currentKw.toString() };
		userSettingsStore.clearWochenMailForTest(req).then(() => {
			notify('Done', '', 'info');
		})
	}

	const testPdfPrint = () => {

		// const path = 'berichte/production/gp/2024/overview/';
		const path = 'berichte/bestand/digitalreport/'

		pdfStore.printPdf2(path).then(() => {
			notify('Done', '', 'info');
		})
	}

	const togglePrintMode = () => {
		runInAction(() => {
			uiStore.printMode = !uiStore.printMode;
		})
	}

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Feedback</div>}>

			<Button type="button" onClick={clearDocsForUser}>Clear Doks for User</Button>


			<Button type="button" onClick={resetWochenMailForUser}>Reset Wochenmail KW: {agtErrungenschaftenUiStore.currentKw}</Button>

			<Button type="button" onClick={testPdfPrint}>Test PDF Druck</Button>

			<Button type="button" onClick={togglePrintMode}>Toggle Print Mode. Currently: {uiStore.printMode ? <>On</> : <>Off</>} </Button>

		</BaseContainer>
	);
});

export default TestClearContainer;
