import { useStore } from 'app/context';
import { UserModel } from 'app/models/core/user.model';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

interface IUserName {
	bnrId: number;
}

export const UserName = observer((props: IUserName) => {
	const { userStore } = useStore();
	const [user, setUser] = useState<UserModel>();

	useEffect(() => {
		let closing = false;
		userStore.findById(props.bnrId).then((u) => {
			if (closing) {
				return;
			}
			setUser(u);
		});
		return () => {
			closing = true;
		};
	}, [props.bnrId, userStore]);

	return <>{user && <strong> {user.name}</strong>}</>;
});
