import { Icon } from 'app/components/common/Icon';
import { Modal } from 'app/components/Modal';
import { Button } from 'app/components/common/Button';
import { DocumentList, Uploads } from 'app/components/documents/DocumentList';
import { DocumentSendModal } from 'app/components/documents/DocumentSendModal';
import { DocumentShareModal } from 'app/components/documents/DocumentShareModal';
import { DocumentViewerModal } from 'app/components/documents/DocumentViewerModal';
import { ITableColumn, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { UserModel } from 'app/models/core/user.model';
import { DocumentTextContent } from 'app/stores/bnr.document.store';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { BnrGpTemplate } from 'app/stores/ui/template.ui.store';
import { formatDate } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { DocumentFilter } from 'app/components/documents/DocumentsFilter';
import { DocumentShareState } from 'app/components/documents/DocumentShareState';

interface IGpDoksModalProps {
	year: number;
	user: UserModel;
}

export const BnrGpDoksModal = observer((props: IGpDoksModalProps) => {
	const { uiStore, documentUiStore } = useStore();
	const modalId = uiStore.modalIds.gpDoks;

	const onCancel = () => {
		runInAction(() => {
			documentUiStore.setCurrentModalDoc(undefined);
			uiStore.hideModal(modalId);
		});
	};

	return (
		<>
			<Modal modalId={modalId} title={'Dokumente für GP ' + props.year + ' ' + props.user.name} onClose={onCancel} size="large" modifier="is-table-modal">
				{props.user && props.year && (
					<>
						<BnrGpDokumente user={props.user} year={props.year} />
					</>
				)}
			</Modal>
		</>
	);
});

export const BnrGpDokumente = observer((props: IGpDoksModalProps) => {
	const { agenturUiStore, documentUiStore, uiStore, bnrProdUiStore } = useStore();

	const addGpDok = async () => {
		let vm = await documentUiStore.addDokumentToPlan(BnrGpTemplate, agenturUiStore.current);
		const c: DocumentTextContent = vm.doc.content as DocumentTextContent;
		vm.doc.title += ' ' + props.year;
		c.bnrGpYear = props.year;
		c.gpBnrId = props.user.bnrId;

		const res = await bnrProdUiStore.findAllForBetreuer(props.user, props.year);
		if (res) {
			let txt = '<b>Geschäftsplan Positionen ' + props.year + '</b><br/><br/>';
			res.forEach((gpPos) => {
				txt += gpPos.posDef.posText + ': <br/><br/>';
			});
			c.text = txt;
		}

		await documentUiStore.save(vm);
		await documentUiStore.refresh();
		vm = documentUiStore.items.find((d) => d.id === vm.id)!;
		documentUiStore.setCurrentModalDoc(vm);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};

	let gpDocsTm: ITableColumn<DocumentVM, number>[] = [];
	gpDocsTm = gpDocsTm.concat([
		{
			label: 'Geteilt',
			path: 'sharedState',
			render: (row: TableRow<DocumentVM>) => {
				return (
					<>
						<DocumentShareState doc={row.data} />
						{row.data.isSharedBy && !row.data.gelesen && <span className="tag is-blue is-small is-inverted">NEU</span>}
					</>
				);
			},
		},
		{
			label: 'Titel',
			path: 'doc.title',
			render: (row: TableRow<DocumentVM>) => {
				return (
					<div className="table-ellipsis" title={row.data.doc.title}>
						{row.data.doc.title}
					</div>
				);
			},
		},
		{
			label: 'Anhänge',
			path: 'uploads.length',
			render: (row: TableRow<DocumentVM>) => {
				return <Uploads doc={row.data} />;
			},
		},
		{
			label: 'Erstellt',
			path: 'doc.created',
			format: (val: any) => {
				return formatDate(val);
			},
		},
		{
			label: 'Geändert',
			path: 'doc.modified',
			format: (val: any) => {
				return formatDate(val);
			},
		},
	]);

	return (
		<div>
			<div className="is-right pad-right-1rem pad-bottom-1rem">
				<Button className="button is-primary" onClick={addGpDok}>
					<span>Kriterien anlegen</span>
					<Icon iconClass="plus" />
				</Button>
			</div>

			<DocumentFilter gpBnrId={props.user.bnrId} bnrGpYear={props.year} isBnrGp={true} hideForm={true} />
			<DocumentList agtId={1} listTm={gpDocsTm} />
			<DocumentShareModal />
			<DocumentSendModal />
			<DocumentViewerModal showPlan={false} showAgt={false} />
		</div>
	);
});
