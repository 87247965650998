import { SessionStore } from '../session.store';
import { FitnessTrackerGraphics } from 'app/components/berichte/fitnesstracker/FitnessTrackerGraphics';
import { JpgThema } from 'app/stores/bnr.document.store';
import { FocusThemaVM } from 'app/stores/ui/document.ui.store';
import { AgtBranchenplanungEdit, AgtBranchenplanungEditModal, AgtJpgBpEditButtons } from 'app/components/anwendungen/branchenplanung/AgtBranchenplanungEdit';
import { AgenturModel } from 'app/models/agentur.model';
import { computed } from 'mobx';
import { BranchenplanungPosDef } from 'app/models/branchenplanung/bp.pos.def';
import { JpgAmisNowQualiContent, JpgAmisNowVorbereitungContent, JpgHandlungsempfehlungen } from 'app/components/documents/JpgThemenContent';
import colors from 'assets/scss/colors.module.scss';

export interface IJpgThemaDef {
	group: string;
	keyThema: string;
	themaShort: string;
	thema: string;
	byLine?: string;
	dataComponent?: (agt: AgenturModel, year: number) => JSX.Element;
	textComponent?: JSX.Element;
	handlungsEmpfehlungen?: string;
	missingDef?: string;
	color?: string;
	background?: string;
}

const missingDataView = (agt: AgenturModel, year: number) => {
	return <>Noch nix definiert</>;
};

export const JpgTextDef: IJpgThemaDef = {
	group: 'Text',
	keyThema: 'text',
	themaShort: '',
	thema: 'Rückblick/Ausblick & Anhänge',
};

export const JpgZieleDef: IJpgThemaDef = {
	group: 'Ziele',
	keyThema: 'ziele',
	themaShort: '',
	thema: 'Ziele',
};

let JpgThemenDef: IJpgThemaDef[] = [
	{
		group: 'ADZ',
		keyThema: 'gup',
		themaShort: 'GUP',
		thema: 'Mein Google Unternehmensprofil',
		byLine: 'Sichtbar sein DigitalPersönlich',
		dataComponent: (agt: AgenturModel, year: number) => <FitnessTrackerGraphics agtId={agt.agtId} view="gup" onClose={() => {}} homeAlone={true} />,
		background: colors.green,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'smt',
		themaShort: 'SMT',
		thema: 'Einsatz der Social Media Toolbox',
		byLine: 'Sichtbar sein DigitalPersönlich PLUS',
		dataComponent: missingDataView,
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'LinkedIn,XING'} />,
		missingDef: 'waiting for FT Data',
		background: colors.green,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'amis',
		themaShort: 'AMIS.NOW',
		thema: 'AMIS.NOW Aufgaben',
		byLine: 'Jeden Kontakt Nutzen',
		dataComponent: (agt: AgenturModel, year: number) => <FitnessTrackerGraphics agtId={agt.agtId} view="amis" onClose={() => {}} homeAlone={true} />,
		background: colors.turquoise,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'sts',
		themaShort: 'S2S',
		thema: 'Service-to-Sale',
		byLine: 'Jeden Kontakt Nutzen PLUS',
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Vertriebschancen im Agenturteam nutzen,Anmeldung Schulung Agenturteam'} />,
		background: colors.turquoise,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'akumaPlaner',
		themaShort: 'AKUMA',
		thema: 'AKUMA Planer und Aktionen',
		byLine: 'Jeden Kunden kontaktieren',
		dataComponent: (agt: AgenturModel, year: number) => <FitnessTrackerGraphics agtId={agt.agtId} view="akumaPlaner" onClose={() => {}} homeAlone={true} />,
		background: colors.yellow,
	},
	{
		group: 'ADZ',
		keyThema: 'akumaPrime',
		themaShort: 'AKUMA Prime',
		thema: 'AKUMA Prime',
		byLine: 'Jeden Kunden kontaktieren PLUS',
		dataComponent: (agt: AgenturModel, year: number) => <FitnessTrackerGraphics agtId={agt.agtId} view="akumaPrime" onClose={() => {}} homeAlone={true} />,
		background: colors.yellow,
	},
	{
		group: 'ADZ',
		keyThema: 'maz',
		themaShort: 'MAZ',
		thema: 'Meine Allianz',
		byLine: 'Immer da sein',
		dataComponent: (agt: AgenturModel, year: number) => <FitnessTrackerGraphics agtId={agt.agtId} view="maz" onClose={() => {}} homeAlone={true} />,
		background: colors.primary,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'mazPlus',
		themaShort: 'KVM',
		thema: 'VU Inbound (KVM)',
		byLine: 'Immer da sein Plus',
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Einrichtung Inbound,Förderung Erreichbarkeit'} />,
		background: colors.primary,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'leadnow',
		themaShort: 'Empfehlungen',
		thema: 'Empfehlungen',
		byLine: 'Neue Kunden finden',
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Empfehlungsmanagement,Kundenzufriedenheit nutzen'} />,
		background: colors.richTurquoise,
		color: colors.white,
	},
	{
		group: 'ADZ',
		keyThema: 'leadnowPlus',
		themaShort: 'LEAD.NOW',
		thema: 'Lead.NOW',
		byLine: 'Neue Kunden finden Plus',
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Prüfung Lead.Now fähig,Einrichtung Lead.Now'} />,
		background: colors.richTurquoise,
		color: colors.white,
	},
	{
		group: 'AMIS.NOW',
		keyThema: 'amisnowVor',
		themaShort: 'AMIS.NOW',
		thema: 'Vorbereitung',
		byLine: 'AMIS.NOW',
		textComponent: <JpgAmisNowVorbereitungContent />,
		background: colors.purple,
		color: colors.white,
	},
	{
		group: 'AMIS.NOW',
		keyThema: 'amisnowQuali',
		themaShort: 'AMIS.NOW',
		thema: 'Qualifizierung',
		byLine: 'AMIS.NOW',
		textComponent: <JpgAmisNowQualiContent />,
		background: colors.purple,
		color: colors.white,
	},
];

BranchenplanungPosDef.forEach((bpPosDef) => {
	JpgThemenDef.push({
		group: 'Branchenplanung',
		keyThema: bpPosDef.planKey,
		thema: bpPosDef.posText,
		themaShort: bpPosDef.posText,
		background: colors.softStone,
		dataComponent: (agt: AgenturModel, year: number) => {
			return (
				<>
					<div className="pad-1rem">
						<AgtBranchenplanungEdit agt={agt} year={year} showErgebnisse={true} hightlightKey={bpPosDef.planKey} />
						<AgtJpgBpEditButtons />
					</div>
					<AgtBranchenplanungEditModal agt={agt} year={year} showErgebnisse={true} />
				</>
			);
		},
		textComponent: (
			<JpgHandlungsempfehlungen handlungsEmpfehlungen={'Schwerpunktagentur setzen, Aktivitäten/Initiativen zur Produktionssteigerung, Aktivitäten/Initiativen zur fachlichen Qualifizierung'} />
		),
	});
});

JpgThemenDef = JpgThemenDef.concat([
	{
		group: 'Kontakt',
		keyThema: 'agtauftritt',
		themaShort: 'Agentur',
		thema: 'Agenturauftritt',
		background: colors.richWater,
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Außenwerbung,Möbelkonzept,Ausstattung '} />,
	},
	{
		group: 'Kontakt',
		keyThema: 'homepage',
		themaShort: 'Homepage',
		thema: 'Homepage',
		background: colors.richWater,
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'SEA (Search Engine Advertising), SEO (Search Engine Optimization),Einbindung Module,Einbindung Email Signatur'} />,
	},
	{
		group: 'Beratung',
		keyThema: 'recht',
		themaShort: 'wEWE',
		thema: 'Recht und Gesetz',
		background: colors.softGrey,
		textComponent: (
			<JpgHandlungsempfehlungen
				handlungsEmpfehlungen={
					'Bedeutung und Notwendigkeit wEWE,Agenturprozesse zur wEWE, dEWE Einholung überprüfen, Überprüfung Kontaktdaten, Digi-ID,Berücksichtigung EU-DSGVO (Einholung dEWE),IDD Erfüllung nachhalten'
				}
			/>
		),
	},
	{
		group: 'Beratung',
		keyThema: 'spezis',
		themaShort: 'Spezialisten',
		thema: 'Spezialisteneinsatz',
		background: colors.softGrey,
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Berücksichtigung Agentur-Cluster,Verstärkte Nutzung DSE,Tarif- und Themenschulung'} />,
	},
	{
		group: 'Nachhaltigkeit',
		keyThema: 'bwl',
		themaShort: 'BWL',
		thema: 'Betriebswirtschaftliche Geamtsituation',
		background: colors.softLime,
		textComponent: (
			<JpgHandlungsempfehlungen handlungsEmpfehlungen={'Überprüfung Ausgabensituation,Überprüfung Einnahmensituation,Neugeschäft,Geschäftsplan - Incentives - Vertriebsschwerpunkte'} />
		),
	},
	{
		group: 'Nachhaltigkeit',
		keyThema: 'kundendaten',
		themaShort: 'NPS',
		thema: 'Kundendaten und NPS',
		background: colors.softLime,
		textComponent: (
			<JpgHandlungsempfehlungen
				handlungsEmpfehlungen={
					'Digital-ID,Ableitungen aus Kundenzufriedenheitsreport,Einholung der Gesellschafts wEWE,Bedarf Personalkapazität,Vorgehensweise Personalsuche Bürokraft und Zweitverkäufer,Personalführung, Coaching'
				}
			/>
		),
	},
	{
		group: 'Nachhaltigkeit',
		keyThema: 'personal',
		themaShort: 'Personal',
		thema: 'Agentur und Personal',
		background: colors.softLime,
		textComponent: <JpgHandlungsempfehlungen handlungsEmpfehlungen={'Bedarf Personalkapazität, Vorgehensweise Personalsuche Bürokraft und Zweitverkäufer, Personalführung & Coaching'} />,
	},
]);

export const mapFocusThemenToVM = (focusThemen: JpgThema[]) => {
	return focusThemen.map((f: JpgThema) => {
		const def = JpgThemenDef.find((d) => d.keyThema === f.key);
		return new FocusThemaVM(def!, f);
	});
};

export const mapAllJpgThemenToVM = (focusThemen: JpgThema[]) => {
	return JpgThemenDef.map((def: IJpgThemaDef) => {
		const f = focusThemen.find((t: JpgThema) => t.key === def.keyThema);
		return new FocusThemaVM(def, f);
	});
};

export class JpgThemenUiStore {
	session: SessionStore;
	constructor(session: SessionStore) {
		this.session = session;
	}

	@computed
	get themen() {
		let themen: IJpgThemaDef[] = [];
		themen = themen.concat(JpgThemenDef);
		return themen;
	}
}
