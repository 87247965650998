import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { InputControl } from 'app/components/common/InputControl';
import { runInAction } from 'mobx';
import { useField, useFormikContext } from 'formik';
import { ZieleIntro } from './JpgThemen';
import { IJpg25 } from 'app/stores/bnr.document.store';

interface IJpgZielItem {
	ziel: string;
	onRemove: (z: string) => void;
}
export const JpgZielItem = observer((props: IJpgZielItem) => {
	return (
		<div className="ziele-list-item">
			<span>{props.ziel}</span>
			<Button className="button is-icon is-delete-button" onClick={() => props.onRemove(props.ziel)}>
				<Icon iconClass="times-circle" />
			</Button>
		</div>
	);
});

export const JpgZieleEditField = observer(({ ...props }) => {
	const [field] = useField('meta'); // a bit ugly. but it works.
	const { setFieldValue } = useFormikContext();
	const meta = field.value as IJpg25;
	if (!meta.ziele) {
		meta.ziele = [];
	}
	const [ziel, setZiel] = useState('');
	const removeZiel = (z: string) => {
		runInAction(() => {
			meta.ziele = meta.ziele.filter((x) => x !== z);
			setFieldValue(props.name, meta);
		});
	};

	const onChange = (name: string, value: string) => {
		setZiel(value);
	};

	const addZiel = () => {
		if (ziel === '') {
			return;
		}
		runInAction(() => {
			meta.ziele.push(ziel);
			setZiel(' ');
		});
		setFieldValue(props.name, meta);
		return;
	};

	return (
		<>
			<ZieleIntro />
			<div className="ziele">
				<div className="ziele-list is-edit-mode">
					{meta.ziele.map((z: string, i: number) => (
						<JpgZielItem key={'z' + i.toString()} ziel={z} onRemove={removeZiel} />
					))}
				</div>
				<div className="ziele-input">
					<InputControl name="fullText" value={ziel} onChange={onChange} placeholder={'Weiteres Ziele eingeben'} autoFocus={true} />
					<Button type="button" className="button is-primary " onClick={addZiel} disabled={!ziel || ziel.length === 0}>
						<Icon iconClass={'plus'}></Icon>
					</Button>
				</div>
			</div>
		</>
	);
});

interface IJpgZiele {
	ziele: string[];
}
export const JpgZiele = observer((props: IJpgZiele) => {
	return (
		<div className="ziele-list is-readonly">
			{props.ziele.map((z, i) => (
				<div className="ziele-list-item" key={'z' + i.toString()}>
					{z}
				</div>
			))}
		</div>
	);
});
