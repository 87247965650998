import { AgenturModel } from 'app/models/agentur.model';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { BpAgtPosModel } from 'app/models/branchenplanung/bp.agt.pos.det.model';
import { BranchenplanungPosDef, FilterBranchenPosDefBySteart } from 'app/models/branchenplanung/bp.pos.def';
import _ from 'lodash';
import { Steart } from 'app/models/core/steart.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtBpBaseModel, AgtBpGslModel, AgtBpStore, DefaultUmrechnungsFaktor, UmrechFaktor } from '../agt.bp.store';
import { SessionStore } from '../session.store';
import { AgtRingPos, AgtRingUiStore } from './agt.ring.ui.store';
import { AgtProdUiStore } from './agt.prod.ui.store';
import { BpEigenesZielModel, KvBnrBpEigenesZielStore } from '../kv.bnr.bp.eigenes.ziel.store';
import { calcZielErriechung, calcZielErriechungZeit } from 'app/utils';
import { BaseUiStore } from './base.ui.store';

export class AgtBpVM extends AgtBpBaseModel {
	constructor(opts: any, agt: AgenturModel, shared: AgtBpBaseModel[], ringValues: AgtRingPos[], produktion?: AgtProdPosVM) {
		super(opts);
		this.agt = agt;
		if (shared) {
			this.shared = shared;
		}
		this.ringZiel = new AgtBpBaseModel({});
		this.ringErgebnis = new AgtBpBaseModel({});
		this.ringZE = new AgtBpBaseModel({});
		this.ringZEZeit = new AgtBpBaseModel({});
		this.countPlanned = new AgtBpBaseModel({});

		let month = 1;
		if (ringValues && ringValues.length > 0) {
			month = ringValues[0].month;
		}
		if (month === 1 && produktion && produktion.posVal) {
			month = produktion.posVal.monat;
		}
		BranchenplanungPosDef.forEach((pos) => {
			const r = ringValues.find((x) => pos.ringPosDef && x.pos.goldPosId === pos.ringPosDef.goldPosId);
			if (r && r.gold) {
				// @ts-ignore
				this.ringZiel[pos.planKey] = r.gold.ziel;
				// @ts-ignore
				this.ringErgebnis[pos.planKey] = r.ergebnis;
			}
			if (pos.planKey === 'gesamtBewertung' && produktion && produktion.posVal) {
				this.ringZiel.gesamtBewertung = produktion.posVal.ziel;
				this.ringErgebnis.gesamtBewertung = produktion.posVal.ergebnis;
			}
			const ziel = _.get(this, pos.planKey) * pos.bewFaktor;
			const ergebnis = _.get(this.ringErgebnis, pos.planKey) * pos.bewFaktor;
			// @ts-ignore
			this.ringZE[pos.planKey] = calcZielErriechung(ziel, ergebnis);
			// @ts-ignore
			this.ringZEZeit[pos.planKey] = calcZielErriechungZeit(ziel, ergebnis, month);
		});
		this.setCountPlanned(this);
	}

	@action
	setCountPlanned(source: any) {
		let count = 0;
		BranchenplanungPosDef.forEach((pos) => {
			const val = (source as any)[pos.planKey];
			if (pos.planKey === 'bestandsZuwachs') {
				// if (val >= 5) {
				// 	sum += 1;
				// }
				if (val > 0) {
					this.countPlanned[pos.planKey] = 1;
					count++;
				}
			} else if (val > 0) {
				// sum += val;
				count++;
				// @ts-ignore
				this.countPlanned[pos.planKey] = 1;
			}
		});
		this.totalCountPlanned = count;
	}

	agt: AgenturModel;
	shared: AgtBpBaseModel[] = [];
	ringZiel: AgtBpBaseModel;
	ringErgebnis: AgtBpBaseModel;
	ringZE: AgtBpBaseModel;
	@observable
	totalCountPlanned: number = 0;
	countPlanned: AgtBpBaseModel;

	ringZEZeit: AgtBpBaseModel;

	totalAgtCount: number = 0;

	// @computed

	@computed
	get myPoses() {
		return FilterBranchenPosDefBySteart(this.user.steart);
	}

	@computed
	get otherPoses() {
		if (this.user.steart.isLeiter) {
			// leiter dürfen nur VBL sehen
			return FilterBranchenPosDefBySteart(this.user.steart);
		}
		const mySteart = this.user.steart.steart;
		const res = BranchenplanungPosDef.filter((p) => {
			return p.canOthersSee(mySteart);
		});
		return res;
	}

	@computed
	get myCountWithValues() {
		let countWithValue = 0;
		this.myPoses.forEach((pos) => {
			const val = _.get(this, pos.prop);
			if (val === 0 || val > 0) {
				countWithValue++;
			}
		});
		return countWithValue;
	}

	@computed
	get otherCountWithValues() {
		let countWithValue = 0;
		const mySteart = this.user.steart.steart;
		this.otherPoses.forEach((pos) => {
			let propCounted = false;
			this.shared.forEach((s) => {
				if (propCounted) {
					return;
				}
				if (!pos.canOthersSee(mySteart)) {
					return;
				}
				if (s.user.steart.steart === mySteart) {
					return;
				}
				if (s.user.steart.isLeiter && this.user.steart.isVbl) {
					// VBL dürfen nicht die Leiter info set
					return;
				}
				const prop = s.user.steart.name + '.' + pos.prop;
				const val = _.get(this, prop);
				if (val === 0 || val > 0) {
					countWithValue++;
					// only count prop once!
					propCounted = true;
				}
			});
		});
		return countWithValue;
	}

	@action
	ensureNumberValues() {
		BranchenplanungPosDef.forEach((bpPosDef) => {
			const prop = bpPosDef.prop;
			let v = (this as any)[prop];
			if (v) {
				let v2 = parseFloat(v);
				(this as any)[prop] = v2;
			} else {
				(this as any)[prop] = null;
			}
		});
	}

	getSharedBySteart = (steart: Steart) => {
		return this.shared.find((b) => b.user.steart.id === steart);
	};

	@computed
	get GSL() {
		let GSL = new AgtBpGslModel({
			bnrId: this.bnrId,
			agtId: this.agtId,
			year: this.year,
		});

		const _vbl = this.shared.find((b) => b.user.steart.id === Steart.VBL);
		const lpv = this.shared.find((b) => b.user.steart.steart === Steart.LPV);
		const lsv = this.shared.find((b) => b.user.steart.steart === Steart.LSV);

		const hasValue = (prop: string, bp: AgtBpBaseModel) => {
			const value = (bp as any)[prop];
			if (value > 0) {
				return true;
			}
			return false;
		};
		const setValue = (prop: string, target: AgtBpGslModel, steart: Steart, bp: AgtBpBaseModel) => {
			const value: number = (bp as any)[prop];
			(target as any)[prop] = value;
			(target as any)[prop + '_steart'] = steart;
		};

		BranchenplanungPosDef.forEach((bpPosDef) => {
			const prop = bpPosDef.prop;
			if (hasValue(prop, this)) {
				setValue(prop, GSL, Steart.GSL, this);
			} else if (_vbl && hasValue(prop, _vbl)) {
				setValue(prop, GSL, Steart.VBL, _vbl);
			} else if (lsv && hasValue(prop, lsv)) {
				setValue(prop, GSL, Steart.LSV, lsv);
			} else if (lpv && hasValue(prop, lpv)) {
				setValue(prop, GSL, Steart.LPV, lpv);
			}
		});
		this.setCountPlanned(GSL);

		return GSL;
	}
	@computed
	get BSG() {
		return this.shared.find((b) => b.user.steart.id === Steart.BSG);
	}

	@computed
	get BBS() {
		return this.shared.find((b) => b.user.steart.id === Steart.BBS);
	}

	@computed
	get KME() {
		return this.shared.find((b) => b.user.steart.id === Steart.KME);
	}

	@computed
	get KVS() {
		return this.shared.find((b) => b.user.steart.id === Steart.KVS);
	}

	@computed
	get LSV() {
		return this.shared.find((b) => b.user.steart.id === Steart.LSV);
	}

	@computed
	get LPV() {
		return this.shared.find((b) => b.user.steart.id === Steart.LPV);
	}

	@computed
	get UB() {
		return this.shared.find((b) => b.user.steart.id === Steart.UB);
	}

	@computed
	get VAS() {
		return this.shared.find((b) => b.user.steart.id === Steart.VAS);
	}

	@computed
	get VBL() {
		return this.shared.find((b) => b.user.steart.id === Steart.VBL);
	}
}

export class AgtBranchenplanungUiStore extends BaseUiStore<AgtBpVM> {
	name = 'AgtBranchenplanungUiStore';
	session: SessionStore;
	agenturStore: AgenturStore;
	agtBpStore: AgtBpStore;
	kvBnrBpEigenesZielStore: KvBnrBpEigenesZielStore;
	agtRingUiStore: AgtRingUiStore;
	agtProdUiStore: AgtProdUiStore;

	selectedPos?: BpAgtPosModel;
	@observable currentUmrechnungsFaktor: UmrechFaktor = DefaultUmrechnungsFaktor;

	constructor(
		session: SessionStore,
		agenturStore: AgenturStore,
		agtBpStore: AgtBpStore,
		kvBnrBpEigenesZielStore: KvBnrBpEigenesZielStore,
		agtRingUiStore: AgtRingUiStore,
		agtProdUiStore: AgtProdUiStore,
	) {
		super();
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtBpStore = agtBpStore;
		this.kvBnrBpEigenesZielStore = kvBnrBpEigenesZielStore;
		this.agtRingUiStore = agtRingUiStore;
		this.agtProdUiStore = agtProdUiStore;
	}

	onLogout() {
		this.agenturStore.onLogout();
		this.agtBpStore.onLogout();
		this.kvBnrBpEigenesZielStore.onLogout();
	}


	async findByYear(year: number) {
		console.time('BP findByear' + year);
		await Promise.all([this.agtRingUiStore.findAll(year), this.load()])
		const items = this.items.filter((x) => x.year === year);
		console.timeEnd('BP findByear' + year);
		return items;
	}

	async getEigenePlanungByYear(year: number) {
		console.time('BP getEigenePlanungByYear' + year);

		const data = await this.kvBnrBpEigenesZielStore.findByYear(year);
		const opts: any = {
			year: year,
		};
		data.forEach((d) => {
			opts[d.planKey] = d.valueNum;
		});
		console.timeEnd('BP getEigenePlanungByYear' + year);

		return new AgtBpBaseModel(opts);
	}

	createEmptyBP = (agt: AgenturModel, year: number): AgtBpVM => {
		const opts = {
			bnrId: this.session.currentUser?.bnrId,
			agtId: agt.agtId,
			year: year,
			user: this.session.currentUser,
		};
		const newAgtBpVm = new AgtBpVM(opts, agt, [], []);
		return newAgtBpVm;
	};

	async findByYearAndAgtId(year: number, agtId: number) {
		await Promise.all([this.agtRingUiStore.findAll(year), this.load()])
		return this.items.find((i) => i.agtId === agtId && i.year === year);
	}



	async _baseLoad() {
		if (this.agtBpStore.disableFind) {
			console.log('BP API is disabled');
			return;
		}
		if (this.agenturStore.session.currentUser!.isMulti) {
			return;
		}


		const res: AgtBpVM[] = [];
		// let bps = (await this.agtBpStore.findAll()).slice();

		const currBnrId = this.session.currentUser!.bnrId;
		const promises = [];
		promises.push(this.agtBpStore.findAll());
		promises.push(this.agenturStore.findAll());
		for (const year of this.agtBpStore.years) {
			promises.push(this.agtRingUiStore.findAll(year));
			promises.push(this.agtProdUiStore.findByPlanKey('produktivitaet', year));
		}
		const ps = await Promise.all(promises);
		let bps = ps[0].slice() as AgtBpBaseModel[];
		let agts = ps[1] as AgenturModel[];


		agts.forEach((agt) => {
			// const agt = agts.find((a) => a.agtId === agtItems[0].agtId);
			let agtItems;
			[agtItems, bps] = this.filterAndSplice(bps, (bp) => bp.agtId === agt.agtId);
			if (!agt) {
				// console.log(`bp: agt with id ${agtItems[0].agtId} not found `);
				return;
			}
			for (const year of this.agtBpStore.years) {
				let ringItems: AgtRingPos[] = [];
				let ringCache = this.agtRingUiStore.cache.find((c) => c.year === year);
				if (ringCache) {
					ringItems = ringCache.items.filter(x => x.agt.agtId === agt.agtId);
				}
				let prodCach = this.agtProdUiStore.cache.find((c) => c.year === year);
				let prodItems: AgtProdPosVM[] = [];
				if (prodCach) {
					prodItems = prodCach.items.filter(x => x.agt.agtId === agt.agtId);;
				}

				let mine = agtItems.find((a) => a.bnrId === currBnrId && a.year === year);
				if (!mine) {
					mine = new AgtBpBaseModel({
						bnrId: currBnrId,
						agtId: agt!.agtId,
						year,
						user: this.session.currentUser,
					});
				}
				const others = agtItems.filter((a) => a.bnrId !== currBnrId && a.year === year);
				// console.log(agt.name, 'leben', mine.leben, 'fak', mine.umrechFaktor, 'uifak', this.currentUmrechnungsFaktor);
				const prod = prodItems.find((r) => r.agt.agtId === agt.agtId && r.posVal && r.posVal.year === year);
				const ring = ringItems.filter((r) => r.agt.agtId === agt.agtId && r.year === year);
				const model = new AgtBpVM(mine, agt, others, ring, prod);
				model.user = this.session.currentUser!.asUserModel;
				res.push(model);
			}
		});
		runInAction(() => {
			this.items = res;
		});
		if (this.loadingPromiseResolver) {
			this.loadingPromiseResolver();
		}

	}

	async saveEigenePlanung(planung: AgtBpVM, year: number, planKey?: string) {
		let items = await this.kvBnrBpEigenesZielStore.findByYear(year);
		if (planKey) {
			items = items.filter((x) => x.planKey === planKey);
			if (!items || items.length === 0) {
				items = [];
				items.push(
					new BpEigenesZielModel({
						bnrId: this.session.currentUser?.bnrId,
						group: 'bp_meinziel',
						key: planKey,
						key2: year.toString(),
					}),
				);
			}
		}

		for (const item of items) {
			item.valueNum = (planung as any)[item.planKey];
			await this.kvBnrBpEigenesZielStore.save(item);
		}
		this.items = [];
		this.setChanged();

	}

	@action
	setChanged() {
		this.changed = new Date();
	}

	@observable
	changed: Date = new Date();

	async saveMany(data: AgtBpVM[]) {
		for (const d of data) {
			await this.agtBpStore.save(d);
		}
		runInAction(() => {
			this.items = [];
			this.loadingPromise = undefined;
			this.agtBpStore.clearCache();
			this.setChanged();
		});
	}
}
