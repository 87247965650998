import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Modal } from '../Modal';
import { DocumentViewer } from './DocumentViewer';

interface IDocumentViewerModal {
	showAgt: boolean;
	showPlan: boolean;
	onCloseComplete?: () => void;
}
export const DocumentViewerModal = observer((props: IDocumentViewerModal) => {
	const { uiStore, documentUiStore } = useStore();
	const modalId = uiStore.modalIds.documentViewModal;

	const onClose = async () => {
		if (documentUiStore.currentModalDoc && documentUiStore.currentModalDoc.isNew) {
			await documentUiStore.deleteById(documentUiStore.currentModalDoc.id);
		}

		runInAction(() => {
			documentUiStore.setCurrentModalDoc(undefined);
			uiStore.hideModal(modalId);
		});

		if (props.onCloseComplete) {
			props.onCloseComplete();
		}
	};

	const isJpg = documentUiStore.currentModalDoc?.isJpg;

	return (
		<>
			{documentUiStore.currentModalDoc && (
				<>
					<Modal modalId={modalId} title={documentUiStore.currentModalDoc.modalTitle} size={isJpg ? 'large' : 'medium'} modifier={isJpg ? 'is-jpg' : ''} onClose={onClose}>
						<DocumentViewer doc={documentUiStore.currentModalDoc} showAgt={props.showAgt} showPlan={props.showPlan} onClose={onClose} />
					</Modal>
				</>
			)}
		</>
	);
});
