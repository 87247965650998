import { RingPosGroups } from 'app/stores/ui/agt.ring.ui.store';
import _ from 'lodash';
import { Einheit } from '../core/einheit.model';
import { Steart, SteartModel } from '../core/steart.model';
import { BpAgtPosModel, IBpAgtPosModel } from './bp.agt.pos.det.model';

const bpPos: IBpAgtPosModel[] = [];

bpPos.push({
	planKey: 'gesamtBewertung',
	posText: 'Gesamtbewertung',
	einheit: Einheit.bew,
	bewFaktor: 1,
	perms: [Steart.VBL, Steart.GSL],
	isConvertible: false,
});
bpPos.push({
	planKey: 'bestandsZuwachs',
	posText: 'Bestandszuwachs',
	einheit: Einheit.proz,
	bewFaktor: 1,
	perms: [Steart.GSL, Steart.VBL, Steart.LSV],
	isConvertible: false,
});

bpPos.push({
	planKey: 'leben',
	posText: 'Leben',
	einheit: Einheit.BSoZe,
	bewFaktor: 0.042,
	perms: [Steart.GSL, Steart.VBL, Steart.VAS, Steart.LPV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510140),
});

bpPos.push({
	planKey: 'fonds',
	posText: 'Fonds',
	einheit: Einheit.bAS,
	bewFaktor: 0.04,
	perms: [Steart.GSL, Steart.VBL, Steart.VAS, Steart.KME, Steart.LPV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510160),
});

bpPos.push({
	planKey: 'kranken',
	posText: 'Kranken',
	einheit: Einheit.MBoD,
	bewFaktor: 9,
	perms: [Steart.GSL, Steart.VBL, Steart.KVS, Steart.LPV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510170),
});

bpPos.push({
	planKey: 'baufi',
	posText: 'Baufinanzierung',
	einheit: Einheit.bDS,
	bewFaktor: 0.04,
	perms: [Steart.GSL, Steart.VBL, Steart.BBS, Steart.LPV],
	isConvertible: true,
});

bpPos.push({
	planKey: 'bauspar',
	posText: 'Bausparen',
	einheit: Einheit.bBS,
	bewFaktor: 0.02,
	perms: [Steart.GSL, Steart.VBL, Steart.BBS, Steart.LPV],
	isConvertible: true,
});

bpPos.push({
	planKey: 'psachOhneKraftUnfall',
	posText: 'P-Sach o. Kr/U',
	einheit: Einheit.NMBT,
	bewFaktor: 1.25,
	perms: [Steart.GSL, Steart.VBL, Steart.LSV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510100),
});

bpPos.push({
	planKey: 'kfzHaftPkw',
	posText: 'Kraft',
	einheit: Einheit.lfNST,
	bewFaktor: 1,
	perms: [Steart.GSL, Steart.VBL, Steart.LSV],
	isConvertible: false,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510120),
});

bpPos.push({
	planKey: 'unfall',
	posText: 'Unfall',
	einheit: Einheit.NMBToA,
	bewFaktor: 1.1,
	perms: [Steart.GSL, Steart.VBL, Steart.BSG, Steart.LSV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510110),
});

bpPos.push({
	planKey: 'firmenSachOhneKraft',
	posText: 'Firmen Sach o. Kr.',
	einheit: Einheit.NMBT,
	bewFaktor: 1,
	perms: [Steart.GSL, Steart.VBL, Steart.BSG, Steart.LSV],
	isConvertible: true,
	ringPosDef: RingPosGroups.find((p) => p.goldPosId === 5510130),
});

export const BranchenplanungPosDef = bpPos.map((x) => new BpAgtPosModel(x));

export const FilterBranchenPosDefBySteart = (steart: SteartModel) => {
	return BranchenplanungPosDef.filter((pos) =>
		_.includes(
			pos.perms.map((p) => p.id),
			steart.id,
		),
	);
};

export const FilterBranchenPosDefBySteartAndPlankey = (steart: SteartModel, planKey: string) => {
	return BranchenplanungPosDef.filter((pos) =>
		_.includes(
			pos.perms.map((p) => p.id),
			steart.id,
		),
	).filter((pos) => pos.planKey === planKey);
};
