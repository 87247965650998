import { Button } from '../common/Button';
import { useStore } from 'app/context';
import { JpgThema } from 'app/stores/bnr.document.store';
import { Formik, Form, Field } from 'formik';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import FormField from '../form/FormField';
import { Modal } from '../Modal';

import * as Yup from 'yup';
import { JpgHandlungsempfehlungen } from './JpgThemenContent';

interface IThemaEditModal {
	onChange: () => void;
	onDelete: () => void;
}

export const JpgThemaEditModal = observer((props: IThemaEditModal) => {
	const { uiStore, documentUiStore, jpgThemenUiStore } = useStore();
	const f = documentUiStore.currentFocusThemaEdit;
	const isNew = documentUiStore.currentFocusThemaEditIsNew;
	const def = jpgThemenUiStore.themen.find((t) => t.keyThema === f?.key);

	const agt = documentUiStore.current!.agt!;
	const year = documentUiStore.current!.planYear!;

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.jpgThemaEdit);
	};
	return (
		<Modal modalId={uiStore.modalIds.jpgThemaEdit} title={def ? def.thema : 'Kein Thema'} size={'large'} modifier="is-form-modal">
			{def && f && (
				<>
					<div className="pad-1rem">
						<JpgThemaEdit thema={f} onChange={props.onChange} onCancel={onCancel} onDelete={props.onDelete} isNew={isNew} />
					</div>

					{def.dataComponent && (
						<>
							<div className="pad-1rem pad-bottom-0rem">
								<strong>Übersicht Daten</strong>
							</div>
							{def.dataComponent(agt, year)}
						</>
					)}
					<div className="pad-1rem">
						{def.textComponent && <>{def.textComponent}</>}
						{def.handlungsEmpfehlungen && <JpgHandlungsempfehlungen handlungsEmpfehlungen={def.handlungsEmpfehlungen} />}
					</div>
				</>
			)}
		</Modal>
	);
});

export const JpgThemaViewModal = observer(() => {
	const { uiStore, documentUiStore, jpgThemenUiStore } = useStore();
	const f = documentUiStore.currentFocusThemaEdit;
	const def = jpgThemenUiStore.themen.find((t) => t.keyThema === f?.key);

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.jpgThemaView);
	};

	if (!def || !f) {
		onCancel();
		return null;
	}

	return (
		<Modal modalId={uiStore.modalIds.jpgThemaView} title={def.thema} modifier="is-form-modal" onClose={onCancel}>
			{def && f && (
				<>
					<div className="content">
						<p>
							{def.group && (
								<>
									<strong>
										{def.group}: {def.byLine}
									</strong>
									<br />
								</>
							)}
							{def.thema && <span>{def.thema}</span>}
						</p>
					</div>
					{f.notiz &&

						<div className="content">
							<strong>Notiz</strong>
							<br />
							<span>{f.notiz}</span>
						</div>
					}
				</>
			)}
		</Modal>
	);
});

interface IJpgThemaEdit {
	thema: JpgThema;
	isNew: boolean;
	onChange: () => void;
	onCancel: () => void;
	onDelete: () => void;
}
const jpgThemaEditSchema = Yup.object({
	notiz: Yup.string(),
});

type JpgThemaEdit = Yup.InferType<typeof jpgThemaEditSchema>;

export const JpgThemaEdit = observer((props: IJpgThemaEdit) => {
	const { uiStore } = useStore();
	const handleSubmit = async (values: JpgThemaEdit) => {
		runInAction(() => {
			props.thema.notiz = values.notiz ? values.notiz : '';
			// props.thema.status = values.status;
		});
		props.onChange();
		uiStore.hideModal(uiStore.modalIds.jpgThemaEdit);
	};

	const onDelete = () => {
		if (window.confirm('Sind Sie sicher, dass Sie das Fokusthema löschen möchten?')) {
			props.onDelete();
			uiStore.hideModal(uiStore.modalIds.jpgThemaEdit);
		}
	};
	return (
		<>
			<Formik
				validationSchema={jpgThemaEditSchema}
				initialValues={{
					notiz: props.thema.notiz ? props.thema.notiz : '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form>
							<div className="grid">
								<FormField error={errors.notiz} touched={touched.notiz} label="Notiz">
									<Field className="input" type="text" as="textarea" name="notiz" placeholder="Notiz" />
								</FormField>
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={props.onCancel}>
									Abbrechen
								</Button>

								{!props.isNew && (
									<>
										<Button type="button" className="button is-danger is-inverted" onClick={onDelete}>
											Fokusthema entfernen
										</Button>
									</>
								)}

								<Button type="submit" className="button is-primary ">
									{props.isNew ? <>Fokusthema anlegen</> : <>Fokusthema übernehmen</>}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
