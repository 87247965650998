import { ISelectOpt } from 'app/components/common/Select';
import { AgenturModel } from 'app/models/agentur.model';
import { Steart, SteartModel } from 'app/models/core/steart.model';
import { ApModel } from 'app/models/documents/ap.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { UserStore } from '../user.store';
import { AgtBpVM, AgtBranchenplanungUiStore } from './agt.branchenplanung.ui.store';
import { AgtRingPos } from './agt.ring.ui.store';
import { AgtSchwerpunktVM, AgtSchwerpunktUiStore } from './agt.schwerpunkt.ui.store';
import { AgtSegmentUiStore, AgtSegmentVM } from './agt.segment.ui.store';
import { DocumentUiStore, DocumentVM } from './document.ui.store';
import { SteartStore } from '../steart.store';
import { AgenturJahresthemenStore, AgtJahresthemenModel } from '../agentur.jahresthemen.store';
import { AgenturFilterUiStore, IAgtFilterable, IFilterOpts, defaultOpts } from './agt.list.filter.ui.store';
import { BaseUiStore } from './base.ui.store';

export class AgenturExtendedVM extends AgenturModel {
	constructor(agt: AgenturModel, docs: DocumentVM[], bp?: AgtBpVM, schwerpunkt?: AgtSchwerpunktVM, seg?: AgtSegmentVM, steart?: SteartModel, themen?: AgtJahresthemenModel) {
		super(agt);
		this.users = agt.users;
		this.bp = bp;
		this.schwerpunkt = schwerpunkt;
		this.docs = docs;
		this.seg = seg;
		this.steart = steart;
		this.jahresthemen = themen;
	}
	bp?: AgtBpVM;
	schwerpunkt?: AgtSchwerpunktVM;
	docs: DocumentVM[];
	seg?: AgtSegmentVM;
	steart?: SteartModel;
	jahresthemen?: AgtJahresthemenModel;

	@computed
	get jahresthemenSort() {
		if (!this.jahresthemen || this.jahresthemen.themen.length === 0) {
			return 'ZZZ';
		}
		return this.jahresthemen.themen.map((t) => t.label).join(', ');
	}

	@computed
	get steartNameShortOrLongOrId() {
		if (this.steart) {
			if (this.steart.name) {
				return this.steart.name;
			}
			if (this.steart.longName) {
				return this.steart.longName;
			}
			return this.steart.id;
		}
	}

	@computed
	get isExtended() {
		if (this.segment !== undefined && this.fullText !== undefined) {
			return true;
		}
		return false;
	}

	@computed
	get segment() {
		if (this.seg) {
			return this.seg.displaySegment;
		}
		return undefined;
	}


	@computed
	get jpgDone() {
		const jpg = this.docs.find((d) => d.isJpg);
		if (jpg && jpg.doc.content && jpg.doc.content.text && jpg.doc.content.text.length > 15) {
			return true;
		}
		if (jpg && jpg.uploads.length > 0) {
			return true;
		}
		return false;
	}

	@computed
	get focusThemen() {
		const jpg = this.docs.find((d) => d.isJpg && d.doc.planYear === moment().year());
		if (!jpg) {
			return [];
		}
		return jpg.focusThemen;
	}

	@computed
	get focusThemenSortString() {
		if (this.focusThemen.length === 0) {
			return 'ZZZ';
		}
		return this.focusThemen.map(x => x.def.thema).join('');
	}

	@computed
	get apDone() {
		const ap = this.docs.find((d) => d.isAp);
		if (!ap) {
			return false;
		}
		const pt = ap.doc.content as ApModel;
		if (pt && pt.baq > 0 && pt.gesamtbewertung > 0) {
			return true;
		}

		return false;
	}

	@computed
	get hasVbl() {
		return !!this.users.find((u) => u.steart.steart === Steart.VBL);
	}

	@computed
	get VBL() {
		return this.users.find((u) => u.steart.steart === Steart.VBL);
	}
	@computed
	get LPV() {
		return this.users.find((u) => u.steart.steart === Steart.LPV);
	}
	@computed
	get LSV() {
		return this.users.find((u) => u.steart.steart === Steart.LSV);
	}
	@computed
	get VAS() {
		return this.users.find((u) => u.steart.steart === Steart.VAS);
	}
	@computed
	get KVS() {
		return this.users.find((u) => u.steart.steart === Steart.KVS);
	}
	@computed
	get KME() {
		return this.users.find((u) => u.steart.steart === Steart.KME);
	}
	@computed
	get BBS() {
		return this.users.find((u) => u.steart.steart === Steart.BBS);
	}
	@computed
	get UB() {
		return this.users.find((u) => u.steart.steart === Steart.UB);
	}

	@computed
	get hasEDPbetreuungFuerMultis() {
		return this.users.find((u) => u.steart.steart === Steart.EDP || u.steart.steart === Steart.VBL);
	}

	@computed
	get fullTextExtended() {
		let s = '';
		s += ` ${this.fullText} `;
		if (this.schwerpunkt) {
			s += ` ${this.schwerpunkt.selectedRolesAsList.join(',')} `;
		}

		if (this.seg) {
			s += ` ${this.seg.displaySegment} `;
		}

		if (this.steart) {
			s += ` ${this.steart.name} `;
		}

		if (this.jahresthemen && this.jahresthemen.themen.length) {
			s += ` ${this.jahresthemen.themen.map((x) => x.label).join(',')} `;
		}

		return s.toLowerCase();
	}
}

class CacheItem {
	constructor() {
		makeObservable(this);
		this.loadingPromise = new Promise((resolve) => {
			this._resolver = resolve;
		});
	}
	@observable
	loaded: boolean = false;
	loading: boolean = false;

	@action
	markAsLoaded = () => {
		if (this._resolver) {
			this._resolver();
			this.loaded = true;
			this.loading = false;
		}
	};
	_resolver: any;
	loadingPromise: Promise<any>;
}

export class AgenturListUiStore extends BaseUiStore<AgenturExtendedVM> {
	name = 'AgenturListUiStore';
	agenturStore: AgenturStore;
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	agtSchwerpunktUiStore: AgtSchwerpunktUiStore;
	documentUiStore: DocumentUiStore;
	agtSegmentUiStore: AgtSegmentUiStore;
	userStore: UserStore;
	steartStore: SteartStore;
	agenturJahresthemenStore: AgenturJahresthemenStore;

	constructor(agenturStore: AgenturStore, userStore: UserStore) {
		super();
		makeObservable(this);
		this.agenturStore = agenturStore;
		this.userStore = userStore;
		this.agtBranchenplanungUiStore = {} as any;
		this.agtSchwerpunktUiStore = {} as any;
		this.documentUiStore = {} as any;
		this.agtSegmentUiStore = {} as any;
		this.steartStore = {} as any;
		this.agenturJahresthemenStore = {} as any;
	}
	setDependenStores(
		agtBranchenplanungUiStore: AgtBranchenplanungUiStore,
		agtSchwerpunktUiStore: AgtSchwerpunktUiStore,
		documentUiStore: DocumentUiStore,
		agtSegmentUiStore: AgtSegmentUiStore,
		steartStore: SteartStore,
		agenturJahresthemenStore: AgenturJahresthemenStore,
		agenturFilterUiStore: AgenturFilterUiStore,
	) {
		this.agtBranchenplanungUiStore = agtBranchenplanungUiStore;
		this.agtSchwerpunktUiStore = agtSchwerpunktUiStore;
		this.documentUiStore = documentUiStore;
		this.agtSegmentUiStore = agtSegmentUiStore;
		this.steartStore = steartStore;
		this.agenturJahresthemenStore = agenturJahresthemenStore;
		this.currentFilter = agenturFilterUiStore;
	}

	currentFilter: AgenturFilterUiStore = new AgenturFilterUiStore({} as any, {} as any, {} as any);

	@action
	applyFilter(items: AgenturModel[] | AgenturExtendedVM[], opts?: IFilterOpts): AgenturModel[] | AgenturExtendedVM[] {
		this.totalItemCount = this.items.length;
		const agtIds = this.items.filter((i) => this.currentFilter.applyFilter(i, opts || defaultOpts)).map((i) => i.agtId);
		this.totalFilterdCount = agtIds ? agtIds.length : 0;
		return items.filter((i) => agtIds.includes(i.agtId));
	}

	@observable
	filteredItems: AgenturExtendedVM[] = [];

	@observable
	totalItemCount: number = 0;

	@observable
	totalFilterdCount: number = 0;

	@action
	applyFilterOnAgt(items: IAgtFilterable[], opts?: IFilterOpts) {
		this.totalItemCount = items.length;
		const agtIds = this.items.filter((i) => this.currentFilter.applyFilter(i, opts || defaultOpts)).map((i) => i.agtId);
		const res = items.filter((i) => agtIds.includes(i.agt.agtId));
		this.totalFilterdCount = res ? res.length : 0;
		return res;
	}

	applyFilterOnRing(items: AgtRingPos[], opts?: IFilterOpts) {
		const agtIds = this.items.filter((i) => this.currentFilter.applyFilter(i, opts || defaultOpts)).map((i) => i.agtId);
		return items.filter((i) => agtIds.includes(i.agt.agtId));
	}

	@computed
	get filterHasChanged() {
		return this.filtered.length + this.currentFilter.changedString;
	}

	@computed
	get filtered() {
		return this.applyFilter(this.items) as AgenturExtendedVM[];
	}

	@computed
	get inspIdOptions() {
		const opts: ISelectOpt[] = [];

		this.items.forEach((agt) => {
			const x = opts.find((o) => o.value === agt.gebiet.inspId.toString());
			if (!x) {
				opts.push({
					label: agt.gebiet.inspId.toString(),
					value: agt.gebiet.inspId.toString(),
				});
			}
		});
		return opts;
	}

	@computed
	get steartMultiOpts() {
		return [
			{
				label: 'LDP',
				value: '1001',
			},
			{
				label: 'EDT',
				value: '1003',
			},
			{
				label: 'EDP',
				value: '1002',
			},
		];
	}

	@action
	async _baseLoad() {
		const isVBL = this.agenturStore.session.isVbl;

		await Promise.all([
			this.userStore.findAll(),
			this.agenturStore.findAll(),
			this.agtBranchenplanungUiStore.load(),
			this.agtSchwerpunktUiStore.load(),
			this.documentUiStore.load(),
			isVBL ? this.agtSegmentUiStore.load() : '',
			this.steartStore.findAll(),
			this.agenturJahresthemenStore.findAll(),
			this.currentFilter.kvBnrAgtFilterStore.findAll(),
		]);

		const year = moment().year();
		// const year = 2025;
		const agts = this.agenturStore.agenturen;
		const users = this.userStore._items;
		let bps = this.agtBranchenplanungUiStore.items.filter((s) => s.year === year).slice();
		let swps = this.agtSchwerpunktUiStore.items.filter((s) => s.year === year).slice();

		const docs = this.documentUiStore.items.filter((s) => s.planYear === year);
		let segs = this.agtSegmentUiStore.items.slice();
		let themen = this.agenturJahresthemenStore._items.slice();
		const res: AgenturExtendedVM[] = [];
		let noEdpCount = 0;

		swps = swps.sort((a, b) => a.agtId - b.agtId);
		segs = segs.sort((a, b) => a.agtId - b.agtId);
		agts.forEach((agt) => {
			const agtId = agt.agtId;
			const agtUsers = users.filter((u) => agt.bnrIds && agt.bnrIds.includes(u.bnrId) && !u.isGSL);
			if (agtUsers.length > 0) {
				agt.users = agtUsers;
			}
			let bp: AgtBpVM;
			[bp, bps] = this.findAndSplice(bps, (b) => b.agtId === agtId);

			let sw: AgtSchwerpunktVM;
			[sw, swps] = this.findAndSplice(swps, (b) => b.agtId === agtId);
			const dcs = docs.filter((b) => b.agtId === agtId);

			let xsegs: AgtSegmentVM[];
			[xsegs, segs] = this.findAndSplice(segs, (b) => b.agtId === agtId);
			let seg;
			if (xsegs) {
				seg = xsegs[0];
			}
			let steart;
			if (agt._steartStringId) {
				steart = this.steartStore.findSteart(parseInt(agt._steartStringId));
			}

			let agtThemen: AgtJahresthemenModel;
			[agtThemen, themen] = this.findAndSplice(themen, (x) => x.agtId === agtId);

			const a = new AgenturExtendedVM(agt, dcs, bp, sw, seg, steart, agtThemen);

			if (this.agenturStore.session.isMulti) {
				// Multis sehen nur agenturen mit EDPs!
				if (!a.hasEDPbetreuungFuerMultis) {
					noEdpCount++;
					return;
				}
			}
			res.push(a);
		});
		console.log('we have ', res.length, ' agt VMs');
		if (noEdpCount) {
			console.log('ohne edp betreuung', noEdpCount);
		}
		runInAction(() => {
			this.items = res;
		});
	}

	@computed
	get itemsForErrungenschaften() {
		return this.items.filter((i) => i.supportsDigiFaktor);
	}

	onLogout() {
		this.agenturStore.onLogout();
	}
}
