import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { AdminImportJobsList } from 'app/components/admin/imports/AdminImportJobsList';
import { AdminImportJobDetailPanel } from 'app/components/admin/imports/AdminImportJobDetail';
import { ImportDataType } from 'app/stores/admin.imports.store';
import moment from 'moment';
import { DatenStandList } from 'app/components/DatenStandList';
import { Button } from 'app/components/common/Button';
import { EnsureAdmin } from './AdminHomeContainer';
import { notify } from 'app/components/common/notify';

export interface AdminUsersParamTypes {
	type?: string;
	id?: string;
}



const AdminImportsContainer = observer((props: RouteComponentProps) => {
	const { adminImportsUiStore, adminImportsStore } = useStore();


	const { type, id } = useParams<AdminUsersParamTypes>();

	const [showDatenStand, setShowDatenStand] = useState(true);

	useEffect(() => {
		if (type === 'job') {
			adminImportsUiStore.loadById(id);
		}
		if (type === 'new') {
			if (id === ImportDataType.aupvorjahr) {
				adminImportsUiStore.newAupVorjahrJob();
			}
			if (id === ImportDataType.aup) {
				adminImportsUiStore.newAupJob();
			}
			if (id === ImportDataType.crawler) {
				adminImportsUiStore.newCrawlerJob();
			}
			if (id === ImportDataType.aladin) {
				adminImportsUiStore.newAladinJob();
			}
			if (id === ImportDataType.aladin2) {
				adminImportsUiStore.newAladin2Job();
			}
			if (id === ImportDataType.amisnow) {
				adminImportsUiStore.adminsNowJob();
			}

			if (id === ImportDataType.leadnow) {
				adminImportsUiStore.newLeadnowJob();
			}

			if (id === ImportDataType.akumaprime) {
				adminImportsUiStore.newAkumaPrime();
			}

			if (id === ImportDataType.akumaplaner) {
				adminImportsUiStore.newAkumaPlaner();
			}

			if (id === ImportDataType.digitalreport) {
				adminImportsUiStore.newDigitalreport();
			}
			if (id === ImportDataType.agenturcluster) {
				adminImportsUiStore.newAgenturClusterJob();
			}

			if (id === ImportDataType.multisagentur) {
				adminImportsUiStore.newAgenturMultisAgenturJob();
			}
			if (id === ImportDataType.multisgp) {
				adminImportsUiStore.newAgenturMultisGPJob();
			}

			if (id === ImportDataType.googleprofile) {
				adminImportsUiStore.newGoogleProfileJob();
			}
		}
	}, [adminImportsUiStore, id, type]);

	const fixDurcheianander = () => {
		notify('gestartet. hoffentlich hilfts');
		adminImportsStore.triggerFixDurcheinander().then(() => {
			notify('fertisch');
		})

	}

	const ImportNavbar = () => {
		return (
			<>
				<div className="admin-navbar">
					<div className="is-left">
						<strong>Daten Importieren</strong>
					</div>
					<div className="is-right">
						<strong>KW {moment().format("WW")}</strong>
					</div>
				</div>
			</>
		);
	};

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<ImportNavbar />}>
			<EnsureAdmin />
			<Button type="button" className="button is-big" onClick={() => setShowDatenStand(true)} disabled={showDatenStand}>Datenstände</Button>
			<Button type="button" className="button is-big" onClick={() => setShowDatenStand(false)} disabled={!showDatenStand}>Import Historie</Button><br />

			<Button type="button" className="button is-big" onClick={() => fixDurcheianander()} disabled={!showDatenStand}>Fix VTNR Durcheinander</Button><br />
			{showDatenStand ?
				<>
					<div className="title">Aktuelle Datenstände</div>
					<DatenStandList />
				</> :
				<>
					<div className="title">Import Historie</div>
					<AdminImportJobsList />
				</>
			}
			<AdminImportJobDetailPanel />
		</BaseContainer>
	);
});

export default AdminImportsContainer;
