import React from 'react';
import { observer } from 'mobx-react';

import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import de_DE from '@react-pdf-viewer/locales/lib/de_DE.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { useStore } from 'app/context';
import { Icon } from './common/Icon';
import FocusTrap from 'focus-trap-react';
import { useEscape } from 'app/utils/hooks';

export const PdfViewer = observer(() => {
	const { uiStore } = useStore();

	const onDownload = () => {
		// save blob to file
		const title = uiStore.showPdfTitle || 'download.pdf';
		const link = document.createElement('a');
		link.href = uiStore.showPdf;
		link.download = title;
		link.click();
		link.remove();
	};

	// download button
	const getFilePluginInstance = getFilePlugin();
	const { Download, DownloadButton } = getFilePluginInstance;

	// modify toolbar
	const toolbarPluginInstance = toolbarPlugin();
	const transformToolbar: TransformToolbarSlot = (slot: ToolbarSlot) => ({
		...slot,
		// These slots will be empty
		Print: () => <></>,
		Open: () => <></>,
		SwitchTheme: () => <></>,
		Download: () => (
			<Download>
				{() => (
					<a onClick={onDownload}>
						<DownloadButton />
					</a>
				)}
			</Download>
		),
	});

	const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transformToolbar)}</Toolbar>;
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar,
	});

	const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

	useEscape(() => {
		closePdf();
	});
	const closePdf = () => {
		uiStore.closePdf();
	};

	if (!uiStore.showPdf) return <></>;
	const title = 'PDF';

	return (
		<>
			<div className="modal-container is-pdf-viewer">
				<div className="modal-background" onClick={() => closePdf()}></div>
				<FocusTrap>
					<div className={'modal is-fullscreen'}>
						<div className="modal-header">
							<div className="title">{title}</div>
							<button className="button is-icon modal-close" onClick={() => closePdf()}>
								<Icon iconClass="times" />
							</button>
						</div>

						<Worker workerUrl="/pdf.worker.min.js">
							<div className="modal-body is-paddingless">
								{/* @ts-ignore */}
								<Viewer localization={de_DE as unknown as LocalizationMap} fileUrl={uiStore.showPdf} plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]} />
							</div>
						</Worker>
					</div>
				</FocusTrap>
			</div>
		</>
	);
});
