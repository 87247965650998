import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { makeObservable, observable, runInAction } from 'mobx';
import { Modal } from 'app/components/Modal';
import { useEffect, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { IJpgThemaDef, JpgTextDef, JpgZieleDef, mapFocusThemenToVM } from 'app/stores/ui/jpg.themen.ui.store';
import { IJpg25, JpgThema } from 'app/stores/bnr.document.store';
import { ITableColumn, TableModel } from '../table/table.model';
import { Jpg25SteartTextOpts } from 'app/models/core/steart.model';
import { Table } from '../table/table';
import * as Yup from 'yup';
import { Button } from '../common/Button';

interface IDocumentSendModalProps {
	// onClose: () => void;
}

export const steartsSchema = Yup.object().shape({
	VBL: Yup.boolean(),
	BSG: Yup.boolean(),
	LPV: Yup.boolean(),
	VAS: Yup.boolean(),
	KVS: Yup.boolean(),
	KME: Yup.boolean(),
	BBS: Yup.boolean(),
	UB: Yup.boolean(),
	LDP: Yup.boolean(),
	EDP: Yup.boolean(),
	EDT: Yup.boolean(),
});

class Jpg25RoleVM {
	index: number = 0;
	def: IJpgThemaDef;
	stearts: string[];
	constructor(def: IJpgThemaDef, stearts: string[], index: number) {
		makeObservable(this);
		this.index = index;
		this.def = def;
		this.stearts = stearts ? stearts : [];

		this.VBL = this.stearts.includes('VBL');
		this.BSG = this.stearts.includes('BSG');
		this.LPV = this.stearts.includes('LPV');
		this.VAS = this.stearts.includes('VAS');
		this.KVS = this.stearts.includes('KVS');
		this.KME = this.stearts.includes('KME');
		this.BBS = this.stearts.includes('BBS');
		this.UB = this.stearts.includes('UB');
		this.LDP = this.stearts.includes('LDP');
		this.EDP = this.stearts.includes('EDP');
		this.EDT = this.stearts.includes('EDT');
	}

	setAll(val: boolean) {
		this.VBL = val;
		this.BSG = val;
		this.LPV = val;
		this.VAS = val;
		this.KVS = val;
		this.KME = val;
		this.BBS = val;
		this.UB = val;
		this.LDP = val;
		this.EDP = val;
		this.EDT = val;
	}

	@observable VBL: boolean;
	@observable BSG: boolean;
	@observable LPV: boolean;
	@observable VAS: boolean;
	@observable KVS: boolean;
	@observable KME: boolean;
	@observable BBS: boolean;
	@observable UB: boolean;
	@observable LDP: boolean;
	@observable EDP: boolean;
	@observable EDT: boolean;

	getStearts() {
		const res: string[] = [];
		Jpg25SteartTextOpts.forEach((s) => {
			if (this[s.label as keyof Jpg25RoleVM]) {
				res.push(s.label);
			}
		});
		return res;
	}
}

export const formatBool = (val: any) => {
	return <input type="checkbox" checked={val} disabled={true} />;
};

export const Jpg25DocumentShareModal = observer((props: IDocumentSendModalProps) => {
	const { uiStore } = useStore();

	const modalId = uiStore.modalIds.documentShareJpg25Modal;

	const { setFieldValue } = useFormikContext();
	const [field] = useField('meta'); // a bit ugly. but it works.
	const meta = field.value as IJpg25;
	const tm = useMemo(() => {
		const tm = new TableModel<Jpg25RoleVM, number>();
		const cols: ITableColumn<Jpg25RoleVM, number>[] = [
			{
				label: 'Thema',
				path: 'def.thema',
				render: (row) => {
					return (
						<>
							{row.data.def.themaShort && <strong>{row.data.def.themaShort}:&nbsp;</strong>}
							{row.data.def.thema}
						</>
					);
				},
			},
		];
		Jpg25SteartTextOpts.forEach((s) => {
			cols.push({
				label: s.label,
				path: s.label,
				format: formatBool,
				editType: 'checkbox',
				editable: true,
			});
		});
		tm.setCols(cols);
		tm.editMode = true;
		tm.hideEditModeHeader = true;
		tm.saveButtonText = 'Übernehmen';
		tm.idType = 'number';
		tm.idProperty = 'index';
		tm.sortDisabled = true;
		return tm;
	}, []);

	const onCancel = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};

	useEffect(() => {
		const focusThemen = meta.focusThemen ? meta.focusThemen : [];
		const all = mapFocusThemenToVM(focusThemen as JpgThema[]);
		const data: Jpg25RoleVM[] = [];
		let i = 0;

		data.push(new Jpg25RoleVM(JpgTextDef, meta.textSteart ? meta.textSteart : [], i++));
		data.push(new Jpg25RoleVM(JpgZieleDef, meta.zieleSteart ? meta.zieleSteart : [], i++));

		all.forEach((t) => {
			data.push(new Jpg25RoleVM(t.def, t.focusThema ? t.focusThema.steart : [], i++));
		});
		tm.setRowData(data);
	}, [meta.focusThemen]);

	const onSave = () => {
		tm.data.forEach((vm) => {
			if (vm.def.keyThema === 'text') {
				meta.textSteart = vm.getStearts();
				return;
			}
			if (vm.def.keyThema === 'ziele') {
				meta.zieleSteart = vm.getStearts();
				return;
			}
			const t = meta.focusThemen!.find((f: any) => f.key === vm.def.keyThema);
			if (t) {
				t.steart = vm.getStearts();
			}
		});
		setFieldValue('meta', meta);
		uiStore.hideModal(modalId);
	};

	const allOrNone = () => {
		const val = !tm.data[0].VAS;
		tm.data.forEach((vm) => {
			vm.setAll(val);
		});
		tm.setRowData(tm.data);
	};

	return (
		<>
			<Modal modalId={modalId} title={'JPG teilen'} modifier="is-paddingless" onClose={onCancel}>
				<div className="pad-1rem">
					<Button type="button" className="button is-small is-primary is-inverted" onClick={allOrNone}>
						Alle / Keine
					</Button>
				</div>
				<div>
					<Table tm={tm} schema={steartsSchema} onSubmit={onSave} onCancel={onCancel} />
				</div>
			</Modal>
		</>
	);
});
