import { observer } from 'mobx-react';
import { IAgtPlanungDoc } from './DocumentViewer';
import { useStore } from 'app/context';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useField, useFormikContext } from 'formik';
import { Button } from '../common/Button';
import { IJpg25, IJpgThema, JpgThema } from 'app/stores/bnr.document.store';
import { runInAction } from 'mobx';
import { JpgThemaEditModal, JpgThemaViewModal } from './JpgThemaEdit';
import { FocusThemaVM } from 'app/stores/ui/document.ui.store';
import { ITableColumn, TableModel } from '../table/table.model';
import { Table } from '../table/table';
import { mapAllJpgThemenToVM, mapFocusThemenToVM } from 'app/stores/ui/jpg.themen.ui.store';
import { JpgZiele, JpgZieleEditField } from './JpgZiele';

export const JpgThemen = observer((props: IAgtPlanungDoc) => {
	const { documentUiStore } = useStore();
	const isEditing = props.doc.doc.id === documentUiStore.currentDocId;
	const [focusThemen, setFocusThemen] = useState<FocusThemaVM[]>();
	const [ziele, setZiele] = useState<string[]>([]);

	useEffect(() => {
		setFocusThemen(props.doc.focusThemen);
		setZiele(props.doc.jpgZiele);
	}, [props.doc]);
	return (
		<>
			{!isEditing && focusThemen && (
				<>
					{ziele.length > 0 && props.doc.isJpg25ZieleVisible && (
						<>
							<ZieleIntro />
							<div className="ziele">
								<JpgZiele ziele={ziele} />
							</div>
						</>
					)}
					{focusThemen.length > 0 && (
						<div className="fokusthemen">
							<div className="fokusthemen-table-container">
								<strong className="title">Fokusthemen</strong>
								<JpgThemenTable editMode={isEditing} data={focusThemen} currentFocusThemen={focusThemen.map((x) => x.focusThema!)} mode="focusThemen" />
							</div>
						</div>
					)}
				</>
			)}
			{isEditing && (
				<>
					<JpgZieleEditField {...props} />
					<FocusThemenEditorField {...props} />
				</>
			)}
		</>
	);
});

export const ZieleIntro = observer(() => {
	return (
		<div className="ziele-intro">
			<strong>Ziele</strong> <br />
			<span>Was will ich konkret in diesem Jahr erreichen?</span>
		</div>
	);
});

export interface IFocusThema {
	key: string;
	status: string;
	notiz: string;
}

export const FocusThemenEditorField = ({ ...props }) => {
	const { documentUiStore } = useStore();
	const { setFieldValue } = useFormikContext();
	const [field] = useField('meta'); // a bit ugly. but it works.
	const meta = field.value as IJpg25;

	const [focusThemen, setFocusThemen] = useState<JpgThema[]>([]);
	const [selectedFocusThemen, setSelectedFocusThemen] = useState<FocusThemaVM[]>([]);
	const [allFocusThemen, setaAllFocusThemen] = useState<FocusThemaVM[]>([]);

	const collectFocusThemenState = () => {
		const fc = meta.focusThemen ? meta.focusThemen : [];
		setFocusThemen(fc as JpgThema[]);
		const s = mapFocusThemenToVM(fc as JpgThema[]);
		setSelectedFocusThemen(s);
		const a = mapAllJpgThemenToVM(focusThemen as JpgThema[]);
		setaAllFocusThemen(a);
	};

	useEffect(() => {
		collectFocusThemenState();
	}, []);

	const onChangeData = () => {
		if (!documentUiStore.currentFocusThemaEdit) {
			return;
		}
		const t = documentUiStore.currentFocusThemaEdit;

		if (!meta.focusThemen) {
			meta.focusThemen = [];
		}
		const existingIndex = meta.focusThemen.findIndex((item: IJpgThema) => item.key === t.key);
		if (existingIndex > -1) {
			meta.focusThemen[existingIndex] = t;
		} else {
			meta.focusThemen.push(t);
		}
		setFieldValue(props.name, meta);
		collectFocusThemenState();
	};

	const onDelete = () => {
		if (!documentUiStore.currentFocusThemaEdit) {
			debugger;
			return;
		}
		const t = documentUiStore.currentFocusThemaEdit;
		if (!meta.focusThemen) {
			meta.focusThemen = [];
		}
		meta.focusThemen = meta.focusThemen.filter((item: IJpgThema) => item.key !== t.key);
		setFieldValue(props.name, meta);
		collectFocusThemenState();
	};

	return (
		<>
			<div className="fokusthemen">
				{focusThemen.length > 0 && (
					<div className="fokusthemen-table-container">
						<strong className="title">Fokusthemen</strong>
						<JpgThemenTable data={selectedFocusThemen} editMode={true} currentFocusThemen={focusThemen} mode="focusThemen" />
					</div>
				)}
				<div className="fokusthemen-table-container">
					<strong className="title">Verfügbare Themen</strong>
					<JpgThemenTable data={allFocusThemen} editMode={true} currentFocusThemen={focusThemen} mode="all" />
				</div>

				<JpgThemaEditModal onChange={onChangeData} onDelete={onDelete} />
			</div>
		</>
	);
};

interface IJpgFocusThemenTable {
	data: FocusThemaVM[];
	editMode: boolean;
	currentFocusThemen: JpgThema[]; // needed for edit
	mode: 'all' | 'focusThemen';
}
export const JpgThemenTable = observer((props: IJpgFocusThemenTable) => {
	const { uiStore, documentUiStore } = useStore();

	const onEditClick = (key: string, isNew: boolean) => {
		let t: JpgThema | undefined;
		if (props.currentFocusThemen) {
			t = props.currentFocusThemen.find((t: JpgThema) => t.key === key);
		}

		if (!t) {
			t = new JpgThema({ key });
		}

		runInAction(() => {
			documentUiStore.currentFocusThemaEdit = t;
			documentUiStore.currentFocusThemaEditIsNew = isNew;
		});
		if (props.editMode) {
			uiStore.showModal(uiStore.modalIds.jpgThemaEdit);
		} else {
			uiStore.showModal(uiStore.modalIds.jpgThemaView);
		}
	};

	const tm = useMemo(() => {
		const tm = new TableModel<FocusThemaVM, number>();
		const cols: ITableColumn<FocusThemaVM, number>[] = [];

		cols.push({
			label: '',
			path: `def.color`,
			cellModifier: 'is-graphic-cell',
			labelModifier: 'is-graphic-cell',
			render: (row) => {
				return <div className="color-bar" style={{ backgroundColor: row.data.def.background || '' }}></div>;
			},
		});

		cols.push({
			label: 'Gruppe',
			path: `def.group`,
		});
		// cols.push({
		// 	label: 'key',
		// 	path: `def.keyThema`,
		// });
		cols.push({
			label: 'Thema',
			path: `def.thema`,
			render: (row) => {
				return (
					<>
						<strong>{row.data.def.thema}</strong> <br />
						<span className="color-grey">{props.mode === 'focusThemen' && row.data.focusThema && <>{row.data.focusThema.notiz}</>}</span>
					</>
				);
			},
		});
		if (props.editMode) {
			cols.push({
				label: '',
				path: `def.byLine`,
			});
		}
		if (props.editMode) {
			cols.push({
				label: '',
				path: `edit`,
				render: (row) => {
					return (
						<>
							{!row.data.isFocusThema && props.mode === 'all' && (
								<Button type="button" className="button is-small is-inverted" onClick={() => onEditClick(row.data.def.keyThema, true)}>
									Hinzufügen
								</Button>
							)}

							{props.mode === 'focusThemen' && (
								<Button type="button" className="button is-small is-inverted is-primary" onClick={() => onEditClick(row.data.def.keyThema, false)}>
									Bearbeiten
								</Button>
							)}
						</>
					);
				},
			});
		}

		tm.onRowClick = (row) => {
			onEditClick(row.data.def.keyThema, props.mode === 'all');
		};

		// tm.sortBy = 'def.group';
		tm.setCols(cols);

		tm.sortDisabled = true;
		tm.idProperty = 'agtId';
		tm.idType = 'number';

		tm.setRowData(props.data);
		return tm;
	}, [props, props.data]);

	// useEffect(() => {
	// 	console.log('JpgThemen', props.data);
	// 	tm.setRowData(props.data);
	// }, [props.data]);

	return (
		<>
			<Table modifier="has-side-border" tm={tm} />
			<JpgThemaViewModal />
		</>
	);
});

export const Jpg25LeitfadenButton = () => {
	return (
		<a className={`button is-inverted is-small is-primary`} href={`JPG25-Leitfaden.pdf`} title={'Leitfaden'} target="_blank" rel="noreferrer">
			Leitfaden
		</a>
	);
};
