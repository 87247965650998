import { useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router';
import { BerichtePanel } from 'app/components/berichte/BerichtePanel';
import { BerichteTreeMenu } from 'app/components/berichte/BerichteTreeMenu';
import { BaseTreeStore, AgtBaseTreeStore } from 'app/stores/core/base.tree.store';
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';
import { runInAction } from 'mobx';

const BerichteNavBar = observer(() => {
	// needed to avoid path loop when switching from berichte to agenturen
	const { agenturListUiStore } = useStore();
	useEffect(() => {
		return () => {
			agenturListUiStore.currentFilter.selectSingleAgtId(undefined);
		};
	});
	return <div>Berichte</div>;
});
const BerichteContainer = (props: RouteComponentProps) => {
	const { berichteUiiStore } = useStore();

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<BerichteNavBar />}>
			<TreePathLoader store={berichteUiiStore} />
			<AgtQueryStringSetter />
			<BerichteTreeMenu />
			<BerichtePanel />
		</BaseContainer>
	);
};

export interface IAgtFilterParms {
	agtId?: string;
	betreuerBnrId?: string;
	segment?: string;
	schwerpunkt?: string;
	fullText?: string;
}

export interface BerichteParamTypes {
	level1?: string;
	level2?: string;
	level3?: string;
	level4?: string;
	level5?: string;
}

interface ILoader {
	store: AgtBaseTreeStore;
}
interface IBaseLoader {
	store: BaseTreeStore;
}

export const QueryParmDef = {
	agtId: ArrayParam,
	betreuerBnrId: StringParam,
	schwerpunkt: StringParam,
	segment: StringParam,
	fullText: StringParam,
	gs: StringParam,
	vd: StringParam,
};
export const BaseTreePathLoader = observer((props: IBaseLoader) => {
	const { agenturListUiStore } = useStore();
	const treeStore = props.store;
	const { level1, level2, level3, level4, level5 } = useParams<BerichteParamTypes>();
	treeStore.isInAgt = false;
	useEffect(() => {
		// if (agenturListUiStore.currentFilter.agtId && agenturListUiStore.currentFilter.agtId.length === 1) {
		// 	treeStore.setSelectedAgtById(agenturListUiStore.currentFilter.agtId[0]);
		// } else {
		// 	treeStore.setSelectedAgtById(undefined);
		// }
		// treeStore.setSelectBnrById(agenturListUiStore.currentFilter.betreuerBnrId);
		treeStore.menu.setActivePath(level1, level2, level3, level4, level5);
		treeStore.menu.openByPath(level1, level2, level3, level4, level5);
	}, [treeStore, level1, level2, level3, level4, level5, agenturListUiStore.currentFilter.singleSelectedAgtId, agenturListUiStore.currentFilter.singleSelectedBetreuerId]);

	return <></>;
});

export const TreePathLoader = observer((props: ILoader) => {
	const { agenturListUiStore } = useStore();
	const treeStore = props.store;
	const { level1, level2, level3, level4, level5 } = useParams<BerichteParamTypes>();
	treeStore.isInAgt = false;
	useEffect(() => {
		if (agenturListUiStore.currentFilter.singleSelectedAgtId) {
			treeStore.setSelectedAgtById(agenturListUiStore.currentFilter.singleSelectedAgtId);
		} else {
			treeStore.setSelectedAgtById(undefined);
		}
		treeStore.setSelectBnrById(agenturListUiStore.currentFilter.singleSelectedBetreuerId);
		treeStore.menu.setActivePath(level1, level2, level3, level4, level5);
		treeStore.menu.openByPath(level1, level2, level3, level4, level5);
	}, [treeStore, level1, level2, level3, level4, level5, agenturListUiStore.currentFilter.singleSelectedAgtId, agenturListUiStore.currentFilter.singleSelectedBetreuerId]);

	return <>{agenturListUiStore.currentFilter.singleSelectedAgtId}</>;
});

export const AgtQueryStringSetter = observer(() => {
	const { agenturListUiStore, uiStore } = useStore();
	const history = useHistory();

	agenturListUiStore.currentFilter.filters.forEach((f) => {
		const [q, setter] = useQueryParam(f.name, StringParam);
		useEffect(() => {
			f.setValueFromQueryString(q);
		}, [q, agenturListUiStore.currentFilter]);
	});

	const [showMap, setShowMap] = useQueryParam('showMap', StringParam);
	useEffect(() => {
		runInAction(() => {
			console.log('show', showMap);
			uiStore.showMap = showMap === '1';
		});
	}, [showMap]);

	useEffect(() => {
		const params = new URLSearchParams(agenturListUiStore.currentFilter.asQuery);
		if (uiStore.showMap) {
			params.append('showMap', '1');
		}
		console.log('update location', agenturListUiStore.currentFilter.asQuery, '->', params.toString());
		history.replace({ search: params.toString() });
	}, [agenturListUiStore.filterHasChanged, agenturListUiStore, history, uiStore.showMap]);

	return <></>;
});

export default BerichteContainer;
