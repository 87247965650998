import React, { useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { IRedirectParms } from 'app/containers/AuthContainers';

const PrintContainer = observer((props: RouteComponentProps) => {
	const { uiStore } = useStore();
	let { redirect } = useParams<IRedirectParms>();
	const history = useHistory();

	useEffect(() => {
		runInAction(() => {
			uiStore.printMode = true;
		});
		if (redirect) {
			redirect = decodeURIComponent(redirect);
			console.log('redirecting to ' + redirect);
			history.push('/' + redirect);
		}
	})
	return <BaseContainer>Setting Print Mode and redirecting to {redirect}</BaseContainer>;
});

export default PrintContainer;
