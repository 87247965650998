import React, { useState, useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { notify } from 'app/components/common/notify';
import { Icon } from 'app/components/common/Icon';

const PrintingContainer = observer((props: RouteComponentProps) => {
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const timer = setTimeout(() => {
			setError('Es ist ein Fehler aufgetreten');
			notify(`Es ist ein Fehler aufgetreten`, '', 'error');
		}, 30000); // 30 seconds

		return () => clearTimeout(timer);
	}, []);

	return (
		<BaseContainer>
			<div className="pad-top-3rem has-text-centered">
				{error ? (
					<>
						<span className="is-size-1">
							<Icon iconClass="exclamation-triangle" faModifier="fal" />
						</span>

						<div className="error-message">{error}</div>
					</>
				) : (
					<>
						<img src="/media/printer.gif" alt="drucker" />
						<h3 className="is-3">Wird gedruckt</h3>
					</>
				)}
			</div>
		</BaseContainer>
	);
});

export default PrintingContainer;
