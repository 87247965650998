import moment from 'moment';
import _ from 'lodash';
import colors from 'assets/scss/colors.module.scss';

export const passwordRegex = /(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;

export const formatDate = (date?: Date, format?: string) => {
	if (!date) {
		return '';
	}
	const mom = moment(date);
	if (mom.year() === 1970 && mom.date() === 1 && mom.month() === 0) {
		return '';
	}

	const f = format ? format : 'DD.MM.YYYY';
	return mom.format(f);
};

export const formatDateLong = (date?: Date) => {
	if (!date) {
		return '';
	}
	const mom = moment(date);
	return mom.format('DD.MM.YYYY, HH:mm');
};

export const formatBM = (bm: number) => {
	const y = bm.toString().substring(0, 4);
	const m = bm.toString().substring(4, 7);

	return `${m}.${y}`;
};

export const formatAblauf = (date?: Date, format?: string) => {
	if (!date) {
		return '';
	}
	let mom = moment(date);
	let today = moment();
	if (mom.year() === 1900) {
		return '';
	} else {
		let diff = mom.diff(today, 'days');
		if (diff <= 0) {
			return 'Abgelaufen';
		} else {
			if (diff < 30) {
				return 'Ablauf ' + moment.duration(diff, 'days').humanize(true);
			} else if (diff < 365) {
				diff = diff / 30;
				return 'Ablauf ' + moment.duration(diff, 'months').humanize(true);
			} else {
				diff = diff / 365;
				return 'Ablauf ' + moment.duration(diff, 'years').humanize(true);
			}
		}
	}
};

export const formatMonth = (monthNum?: number, short?: boolean) => {
	if (!monthNum) {
		return '';
	}

	return moment()
		.month(monthNum - 1)
		.format(short ? 'MMM' : 'MMMM');
};

export const formatNumberToDate = (date?: Date, format?: string) => {
	if (!date) {
		return '';
	}
	const mom = moment(date);
	const f = format ? format : 'DD.MM.YYYY';
	return mom.format(f);
};

export const formatCurrency = (number: number, round: number = 0, showCurrency: boolean = false) => {
	if (isNaN(number)) {
		number = 0;
	}
	// added minimumFractionDigits: 0 as possible fix for
	// https://sentry.io/organizations/isarbits/issues/2551250154/?project=5890677&query=is%3Aunresolved&statsPeriod=14d
	// see -> https://github.com/tc39/ecma402/issues/239
	const result = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: round }).format(number);
	if (showCurrency) {
		return result;
	} else {
		return result.replace(/€/i, '').trim();
	}
};

export const formatKwArray = (kws: number[]): string => {
	// make kws look a bit nicer...
	if (kws.length === 0) return '-';
	if (kws.length === 1) return formatKw(kws[0]);

	return `${formatKw(kws[0])} bis ${formatKw(kws[kws.length - 1])}`;
};

export const formatKw = (kw: number): string => {
	const kwString = kw.toString();
	return kwString.substring(0, 4) + '-' + kwString.substring(4);
};

export function isNumeric(str: any) {
	if (typeof str === 'number') return true;
	if (typeof str != 'string') return false; // we only process strings!
	return (
		// @ts-ignore
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

// catch all default number format
export const formatNum = (val: number) => {
	return formatCurrency(val, 0, undefined);
};
export const formatNumTwoDig = (val: number) => {
	return formatCurrency(val, 2, undefined);
};
export const formatDelta = (val: number) => {
	const delta = formatNum(val);

	return val < 0 ? delta : `+${delta}`;

	//return formatCurrency(val, 0, undefined);
};

export const formatPercentTwoDig = (number: number) => {
	return formatPercent(number, 2) + ' %';
};

export const formatPercent = (number: number, round: number = 0) => {
	if (isNaN(number)) {
		number = 0;
	}

	const result = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: round, maximumFractionDigits: round }).format(number);
	return result;
};

export const formatPercentWithIcon = (number: number, round: number = 0) => {
	if (isNaN(number)) {
		number = 0;
	}

	const result = new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: round, maximumFractionDigits: round }).format(number);
	return `${result}%`;
};

export const calcZielErriechung = (z: number, ergebnis: number) => {
	if (z === 0) {
		return 0;
	}
	const e = ergebnis;
	let ze = (e / z) * 100;
	ze = Math.round(ze * 100) / 100;
	return ze;
};

export const calcZielErriechungZeit = (z: number, ergebnis: number, monat: number) => {
	if (z === 0) {
		return 0;
	}
	z = (z * monat) / 12;
	if (z === 0) {
		return -1;
	}
	const e = ergebnis;
	let ze = (e / z) * 100;
	ze = Math.round(ze * 100) / 100;
	return ze;
};

export const formatGp = (gpId: number) => {
	switch (gpId) {
		case 1110:
			return 'Basisvariante';
		case 1111:
			return 'Variante 10';
		case 1113:
			return 'Variante 30';
		case 1114:
			return 'Variante 40';
		case 1115:
			return 'Variante 50';
		case 1116:
			return 'Variante 60';
		case 1117:
			return 'Variante 70';
		case 1119:
			return 'Variante 90';
		case 1130:
			return 'Pro3';
		case 1140:
			return 'KVVT';
		default:
			return '';
	}
};

export const parseDateOrUndef = (val: any) => {
	if (val) return new Date(val);
};

export const Round2 = (num: number) => {
	return Math.round((num + Number.EPSILON) * 100) / 100;
};

interface IFileTypeMapping {
	mime: string;
	icon: string;
	name: string;
	color: string;
	abbr: string;
}

export const fileInformationFromType = (fileType: string) => {
	const defaultType = { mime: 'application/octet-stream', icon: 'file-o', name: 'Datei', abbr: 'dat', color: 'filetype-default' };
	const typeMapping: IFileTypeMapping[] = [
		{ mime: 'image/gif', icon: 'file-image-o', name: 'Bilddatei', abbr: 'gif', color: 'filetype-image' },
		{ mime: 'image/png', icon: 'file-image-o', name: 'Bilddatei', abbr: 'png', color: 'filetype-image' },
		{ mime: 'image/jpg', icon: 'file-image-o', name: 'Bilddatei', abbr: 'jpg', color: 'filetype-image' },
		{ mime: 'image/jpeg', icon: 'file-image-o', name: 'Bilddatei', abbr: 'jpeg', color: 'filetype-image' },
		{ mime: 'audio', icon: 'file-audio-o', name: 'Audiodatei', abbr: 'audio', color: 'filetype-audio' },
		{ mime: 'video', icon: 'file-video-o', name: 'Videodatei', abbr: 'video', color: 'filetype-video' },
		{ mime: 'application/pdf', icon: 'file-pdf-o', name: 'PDF-Datei', abbr: 'pdf', color: 'filetype-pdf' },
		{ mime: 'application/msword', icon: 'file-word-o', name: 'Word-Dokument', abbr: 'doc', color: 'filetype-word' },
		{ mime: 'application/vnd.ms-word', icon: 'file-word-o', name: 'Word-Dokument', abbr: 'doc', color: 'filetype-word' },
		{ mime: 'application/vnd.oasis.opendocument.text', icon: 'file-word-o', name: 'Word-Dokument', abbr: 'doc', color: 'filetype-word' },
		{ mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml', icon: 'file-word-o', name: 'Word-Dokument', abbr: 'doc', color: 'filetype-word' },
		{ mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: 'file-word-o', name: 'Word-Dokument', abbr: 'doc', color: 'filetype-word' },
		{ mime: 'application/vnd.ms-excel', icon: 'file-excel-o', name: 'Excel-Dokument', abbr: 'xls', color: 'filetype-excel' },
		{ mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml', icon: 'file-excel-o', name: 'Excel-Dokument', abbr: 'xls', color: 'filetype-excel' },
		{ mime: 'application/vnd.oasis.opendocument.spreadsheet', icon: 'file-excel-o', name: 'Excel-Dokument', abbr: 'xls', color: 'filetype-excel' },
		{ mime: 'application/vnd.ms-powerpoint', icon: 'file-powerpoint-o', name: 'Powerpoint-Datei', abbr: 'ppt', color: 'filetype-ppt' },
		{ mime: 'application/vnd.openxmlformats-officedocument.presentationml', icon: 'file-powerpoint-o', name: 'Powerpoint-Datei', abbr: 'ppt', color: 'filetype-ppt' },
		{ mime: 'application/vnd.oasis.opendocument.presentation', icon: 'file-powerpoint-o', name: 'Powerpoint-Datei', abbr: 'ppt', color: 'filetype-ppt' },
		{ mime: 'text/plain', icon: 'file-text-o', name: 'Text-Datei', abbr: 'txt', color: 'filetype-text' },
		{ mime: 'text/html', icon: 'file-code-o', name: 'HTML-Datei', abbr: 'html', color: 'filetype-html' },
		{ mime: 'application/json', icon: 'file-code-o', name: 'JSON-Datei', abbr: 'json', color: 'filetype-json' },
		{ mime: 'application/gzip', icon: 'file-archive-o', name: 'ZIP-Datei', abbr: 'zip', color: 'filetype-zip' },
		{ mime: 'application/zip', icon: 'file-archive-o', name: 'ZIP-Datei', abbr: 'zip', color: 'filetype-zip' },
	];

	return _.find(typeMapping, { mime: fileType }) || defaultType;
};

export const humanizeFileSize = (bytes: number) => {
	let thresh = 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	let units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	let u = -1;

	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);

	return bytes.toFixed(1) + ' ' + units[u];
};

export const roundPercentages = (arr: number[]) => {
	let rounded = arr.map(Math.round);
	let sum = rounded.reduce((a, b) => a + b, 0);
	if (sum === 0) return arr;

	while (sum > 100) {
		let indexMax = rounded.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
		rounded[indexMax]--;
		sum--;
	}

	while (sum < 100) {
		let indexMax = rounded.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
		rounded[indexMax]++;
		sum++;
	}

	return rounded;
};

export const calculatePercentages = (arr: number[]) => {
	// calcualte percentages for a given array of numbers
	const total = countArray(arr);
	if (total === 0) return arr;

	return roundPercentages(arr.map((val) => (val / total) * 100));
};

export const getColor = (n?: number | 'all') => {
	const colorArr = [
		colors.blue,
		colors.yellow,
		colors.green,
		colors.purple,
		colors.richRed,
		colors.darkYellow,
		colors.darkGreen,
		colors.orange,
		colors.red,
		colors.turquoise,
		colors.richGreen,
		colors.richBlue,
		colors.brown,
		colors.richTurquoise,
	];

	if (n === 'all') {
		return colorArr;
	}
	const num = n ? n : Math.floor(Math.random() * colorArr.length);
	return colorArr[num];
};

export const countArray = (vals: any[], key?: string) => {
	const sum = vals.reduce((acc, val) => {
		if (typeof val !== 'number' && !key) {
			console.error('countArray NaN: object array passed without key?');
		}
		return acc + (key ? val[key] : val);
	}, 0);

	return sum;
};
